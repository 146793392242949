import {useEffect, useRef, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import general from "../../general";
import {useLockFn, useScroll, useMount} from "ahooks";
import Dialog from "../../lib/Dialog";
import {NavLink} from "../../lib/Navigation";
import useActiveState from "../../lib/useActiveState";
import Image from "../../lib/Image";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {CSSTransition} from "react-transition-group";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination} from "swiper";
import moment from "moment";
import URI from "urijs";
import useUrlState from "@ahooksjs/use-url-state";
import { CloseOutlined } from "@ant-design/icons";
import Subtitle from "../components/Subtitle";
import Manager from "../components/Manager";
import SignAlert from "../game/gamesign/SignAlert";
import { Modal } from "antd-mobile";

export default props => {
  const {aplus_queue} = window;
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const location = useLocation();
  const history = useHistory();
  const [agent] = useActiveState('agent');
  const [data, setData] = useState([]);
  const [task, setTask] = useState([]);
  const [goodsWelfare, setGoodsWelfare] = useState([]);
  const [special, setSpecial] = useState([]);
  const [active, setActive] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [gift, setGift] = useState([]);
  const [user, setUser] = useActiveState('user');
  const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
  const [config] = useActiveState('config');
  const [authData, setAuthData] = useState({}); //实名认证参数值
  const [opacity, setOpacity] = useState();

  const [trumpetState, setTrumpetState] = useState(false);//是否有小号状态--领取礼包
  const [gamePubId, setGamePubId] = useState();
  const [init, setInit] = useState();
  const [alertType, setAlertType] = useState('');
  const ref = useRef()
  const [visible, setVisible] = useState(false)
  const [giftData, setGiftData] = useState()
  const [signgift, setSigngift] = useState()
//   useMount(() => {
//     general.axios.post('/welfare/getIndexData')
//         .finally(() => {
//           Dialog.close();
//         })
//         .then(res => {
//           let {status, data} = res.data;
//           if (!status.succeed) {
//             Dialog.error(status.error_desc);
//             return;
//           }
//           setData(data);
//           setTask(data.task);
//           setGoodsWelfare(data.goodsWelfare);
//           setSpecial(data.special);
//           setActive(data.active);
//           setUserInfo(data.userInfo);
//         })
//         .catch(err => {
//           Dialog.error(err.message);
//         });
//   });

  const load =  useLockFn(async () => {
    general.axios.post('/user/Welfare2407/getIndexData')
    .finally(() => {
      Dialog.close();
    })
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        if(status.error_desc != 'no_access') {
            Dialog.error(status.error_desc);
        }
        return;
      }
      setInit(true);
      setData(data);
      setTask(data.task);
      setGoodsWelfare(data.goodsWelfare);
      if(data.special.items.length > 0) {
        setSpecial(data.special);
      }
      setActive(data.active);
      setUserInfo(data.userInfo);
      setGift(data.gift);
      setSigngift(data.signgift);
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  })

  useEffect(() => {
    if(props.active) {
      load();
    }
  }, [props.active]);

  const urlLogin = () => {
    if(general.isLogin() && localStorage.getItem('username') == params.username) {
      return;
    }

    if (params.userid && params.username && params.token) {
      localStorage.setItem('user_id', params.userid);
      localStorage.setItem('username', params.username);
      localStorage.setItem('token', params.token);

      load_load();
      load();
    }
  }
  const load_load = useLockFn(async () => {
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {

        } else {
          setUser({isLogin: true, ...data.member_info});
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })
  useMount(urlLogin);

  const receive = item => {

    if(!general.isLogin()) {
      PubSub.publish('alert-login',true)
      return;
    }
    // console.log(item);
    general.axios.post('/receiveVoucher', {voucher_id: item.voucherId})
    .finally(() => {
        Dialog.close();
    })
    .then(res => {
        let {status} = res.data;
        if (!status.succeed) {
            Dialog.error(status.error_desc);
        } else {
          let available_voucher = (parseInt(user.available_voucher) + 1).toString();
          setUser({...user, ...{available_voucher: available_voucher}});
          Dialog.success('领取成功');
          load();
        // item.is_received = true;
        // update();
        }
    })
    .catch(err => {
        Dialog.error(err.message);
    });
  }

  const [cdking, setCdking] = useState(false);
  const [cdkCode, setCdkCode] = useState('');
  const [cdkExchanging, setCdkExchanging] = useState(false);
  const cdkExchange = () => {
    if (!cdkCode) {
      Dialog.error('请输入兑换码');
      return;
    }
    if (cdkExchanging) {
      return;
    }
    setCdkExchanging(true);
    Dialog.loading();
    general.axios.post('/user/task/exchange', {type: 'active', code: cdkCode})
        .finally(() => {
          setCdkExchanging(false);
          Dialog.close();
        })
        .then(response => {
          let result = response.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            Dialog.success('领取成功');
            setCdkCode(null);
            setCdking(null);
            // taskInfo.active.completed = true;
            // setTaskInfo(taskInfo);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  
  const [giftDescInfo, setGiftDescInfo] = useState({});
  const [giftDescInfoImage, setGiftDescInfoImage] = useState({});
  const [giftShow, setGiftShow] = useState();
  
  //领取礼包--特权
  const receive_gift = item => {
    Dialog.loading();
    general.axios.post('/base/game/getReceiveGift', {gift_id: item.gift_id})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        } else {
          Dialog.info("领取成功");
          setGiftShow(false);
          load();
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });

    // Dialog.loading();
    // general.axios.post('/base/game/getReceiveGift', {gift_id: item.gift_id})
    //   .finally(() => {
    //     Dialog.close();
    //   })
    //   .then(res => {
    //     let {status, data} = res.data;
    //     if (!status.succeed) {
    //       Dialog.error(status.error_desc);
    //       return;
    //     }
    //     Dialog.info("领取成功");
    //     setGiftShow(false);
    //     load();
    //   })
    //   .catch(err => {
    //     Dialog.error(err.message);
    //   });
  }

  //特权礼包弹框
  const bouncesShow = item => {
   
    if (item.is_sign){
      return
    }
    
    if(!general.isLogin()) {
      PubSub.publish('alert-login',true)
      return;
    }

    if (!item.is_can_sign){
      Modal.show({
        title: '登录游戏后即可领取礼包',
        closeOnMaskClick: true,
        className:'game-sign-title',
        header:(
          <div>
            <img src="resources/game/sign/礼包.png" width={58} style={{marginBottom:'10px'}} alt="" />
          </div>
        ),
        content: (
          <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
            <div onClick={()=>{
              Modal.clear()
              general.history.push(`/game?id=${item.appid}`)
            }} style={{
              backgroundColor:'var(--MainColor)',
              width:'183px',height:'44px',
              color:'white',fontSize:'15px',textAlign:'center',
              borderRadius:'22px',lineHeight:'44px',marginTop:'10px'
              }}>下载游戏</div>
            <div onClick={()=>{
              Modal.clear()
            }} style={{
              fontSize:'13px',color:'#999',paddingTop:'10px'
            }}>我知道了</div>
          </div>
        ),
      })
      return
    }

    Dialog.loading();
    general.axios.post('/base/signbonus/sign', {
      topic_id: item.maiyou_gameid,
    }).finally(()=>{
      Dialog.close();
    }).then(res => {
      const { data, status } = res.data
      if (status.succeed) {
        setGiftData(data)
        setVisible(true)
        load()
      } else {
        Dialog.error(status.error_desc)
      }
    })
  }


  //实名认证
  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
    Dialog.loading();
    general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;

        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("认证成功");
          setIsCheckAuth(false);
          setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  })


  const scroll = useScroll(ref);
  useEffect(() => {
    let opacity;
    if (props.active) {
      if (scroll.top) {
        opacity = Math.abs(scroll.top) / 200;
        if (opacity > 1) {
          opacity = 1;
        }
      } else {
        opacity = 0;
      }
    }
    setOpacity(opacity);

    if (props.active) {
      if (scroll.top < -80) {
        load();
      }
    }
  }, [scroll, props.active]);

  useEffect(() => {
    if(props.active) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['TaskCenterViewAppear', 'CLK', {age: user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), agent: agent}]
      });
    }
  }, [props.active]);

  return <div><div className="welfare-index" ref={ref} style={{backgroundImage: 'url(/resources/welfare/bgg.png)'}}>
    <div className="welfare-halo width" style={{opacity: opacity !== undefined ? opacity : 1}}>
      <div className="background" />
      <div className="container">
        福利中心
      </div>
    </div>
    {data.goodsWelfare?.length > 0 && <div className="welfare-head">
        <div className="head-title">
          <NavLink className="icon"><Image src={ !userInfo.account ? '/resources/user/avatar.png' : userInfo.avatar } alt="个人头像" className="avatar"/></NavLink>
          <div className="info">
              {userInfo.account ? 
              <>
                <div className="name">
                <div className="nick_name">{userInfo.nickname}</div>
                {(() => {
                  if(parseInt(userInfo.vip_level) == 1 && userInfo.account) {
                    return <div className='rank-level'><img src="/resources/user/monthly_card.png" /></div>
                  } else if(parseInt(userInfo.vip_level) == 2 && userInfo.account) {
                    return <div className='rank-level'><img src="/resources/user/season_card.png" /></div>
                  } else if(parseInt(userInfo.vip_level) == 3 && userInfo.account) {
                    return <div className='rank-level'><img src="/resources/user/year_card.png" /></div>
                  }
                })()}
                {/* {user.vip_level > 0 ? <img src={'/resources/user/' + user.vip_level + '.png'}/> : null} */}
                </div>
                <div className="desc">
                    账号：{userInfo.account}
                </div>
                </>
              : <NavLink style={{
                fontSize:'16px',
                color:'#282828'
              }}>未登录</NavLink>}
            </div>
        </div>

        <div className="head-groups">
            <div className="items clearfix">
                {task?.map((item,index) => 
                    <NavLink user={item.name == 'inviteFriends' || item.name == 'trypaly' || item.name == 'welfare' || item.name == 'daysign' ? false : true} to={(()=>{
                        if(item.name == 'novice') {
                            return "/task/novices";
                        } else if (item.name == 'achievement') {
                            return "/task/success";
                        } else if (item.name == 'today') {
                            return "/task/day";
                        } else if (item.name == 'shop') {
                            return "/shop";
                        } else if (item.name == 'welfare') {
                          return "/task/welfare";
                        } else if (item.name == 'daysign') {
                          return "/task/signdata";
                        } else if (item.name == 'trypaly') {
                          return "/task/tryplay";
                        } else if (item.name == 'inviteFriends') {
                          return config?.inviteFriendsType == 2 ? "/newinvite" : "/invite";
                        }
                    })()} key={index} className="item" onClick={()=>{
                      Manager.push({event_id:item.event_id})
                    }}>
                    <div className="icon"><img src={item.icon} alt=""/></div>
                    <div className="info">
                        <div className="name">{item.title}</div>
                        <div className="value">{item.desc}</div>
                    </div>
                    </NavLink>
                )}
            </div>
        </div>
    </div>}

    {/* {
        user.isRealNameAuth ? null :
        <div className="welfare-cert-auth" style={{backgroundImage: 'url(/resources/welfare/cert-bg.png)'}} 
          onClick={()=>{
            if(!general.isLogin()) {
              history.push(URI('/verificode').addQuery('callback', location.pathname + location.search + location.hash).toString());
              return;
            }
            setIsCheckAuth(true)
          }}
        >
            <div className="welfare-cert-first-part">
                <img src="/resources/welfare/new-cert-text.png"/>
            </div>
            <div className="welfare-cert-second-part">
                立即认证>
            </div>
        </div>
    } */}

    {goodsWelfare.length > 0 && <div className="welfare-task">
        <div className="plat-title title-img" style={{
          backgroundImage: 'url(/resources/welfare/welfare_item_bg.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize:'100% 23px'
        }}>
            <img src="/resources/welfare/精选福利.png" />
        </div>
        <div className="welfare-main">
            <div className="items">
                {
                    goodsWelfare?.map((item,index)=>
                        <div>
                        {(() => {
                          return <NavLink to={(()=>{
                              if(item.name == 'oneDiscountNumber') {
                                  return `/index/trade?title=${item.title}`;
                              } else if (item.name == 'alt') {
                                  return "/recovery";
                              } else if (item.name == 'gift') {
                                  return item.url;
                              } else if (item.name == 'activeGift') {
                                  return '';
                              } else if (item.name == 'transGame') {
                                  return '/around';
                              } else if (item.name == 'awardedReview') {
                                  return '';
                              }
                          })()} iframe={(()=>{
                              if (item.name == 'gift') {
                                  return {title:item.title}
                              }
                          })()} className="item" onClick={(e)=>{
                            Manager.push({event_id:item.event_id})
                            if (item.isAuthOpen){
                              e.stopPropagation()
                              e.preventDefault()
                              setIsCheckAuth(true)
                              return
                            }
                            if (item.name == 'awardedReview' || item.name == 'activeGift') {
                              setAlertType(item.name)
                              setCdking(true)
                            }
                          }}>
                              <div className="icon"><img src={item.icon} alt=""/></div>
                              <div className="info">
                                  <div className="name">{item.title}</div>
                                  <div className="value">{item.desc}</div>
                              </div>
                              <div className="welfare-back">
                                  <img src="/resources/welfare/left-back.png" />
                              </div>
                          </NavLink>
                        })()}
                        {index == goodsWelfare.length-1 ? null : <div style={{
                          backgroundColor:'#E6E6E6',
                          height:'0.5px',
                          margin:'5px 0px 10px 60px'
                        }}></div>}
                        </div>
                    )
                }
            </div>
        </div>
    </div>}

    {special?.items?.length > 0 && <div className="welfare-first-charge" style={{
              backgroundImage: 'url(/resources/welfare/welfare_item_bg.png)',
              backgroundRepeat: 'no-repeat',
              backgroundSize:'100% 23px'
            }}>
        <div className="first-charge-title" >
            <div className="plat-title title-img img" >
                <img src="/resources/welfare/648代金券.png" />
            </div>
            
            <Link onClick={()=>{
              Manager.push({event_id:"A-3-12"})
              aplus_queue.push({
                    action: 'aplus.record',
                    arguments: ['welfare_center_648_coupon', 'CLK', {}]
                  });
            // }} to={"/game/project?id=" + special.id} className="more">
            }} to={"/giftproject"} className="more">
                更多
            </Link>
        </div>
        <div className="welfare-main">
            <div className="items clearfix">
                {special.items?.map((item,index) => 
                    <div onClick={()=>{
                      Manager.push({event_id:"A-3-12"})
                    }} className="item" key={index}>
                        <div className="voucher-name">
                            <Link to={'/game?id='+item.gameId}>
                                <div className="icon"><img src={item.logo} alt=""/></div>
                            </Link>
                            <div className="info">
                              {item.gameName}
                              {
                                item.nameRemark ?
                                <span className="nameRemark-new">
                                  {item?.nameRemark}
                                </span>
                                : null
                              }
                            </div>
                        </div>
                        <div className="vouchar-desc">
                            <div className="info">
                                <div className="des1">
                                    ￥{item.amount}
                                </div>
                                <div className="des2">
                                    {item.desc}
                                </div>
                            </div>
                            <div className="operate">
                                {item.received ? 
                                <div className="alreadly-operate-btn">已领取</div>
                                :
                                <div className="operate-btn"  onClick={() => receive(item)} >领取</div>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>}

    {/*<div className="welfare-active">
        <div className="welfare-main">
            <div className="active-title title-img">
                <img src="/resources/welfare/welfare-active.png" />
            </div>
            <Swiper>
                {active.map(item => <SwiperSlide>
                    <NavLink className="item" to={item.url} iframe={{title: item.title}}>
                    <div className="active-img">
                        <img src={item.cover} />
                    </div>
                    <div className="active-text">
                        {item.title}
                    </div>
                </NavLink>
                </SwiperSlide>)}
            </Swiper>
        </div>
    </div>*/}

    {gift?.items?.length > 0 && 
    <div className="welfare-project-items" style={{
              backgroundImage: 'url(/resources/welfare/welfare_item_bg.png)',
              backgroundRepeat: 'no-repeat',
              backgroundSize:'100% 23px'
            }}>
        <div className="project-items-title">
            <div className="plat-title title-img img">
                <img src="/resources/welfare/648礼包.png" />
            </div>
            
            <Link onClick={()=>{
              aplus_queue.push({
                    action: 'aplus.record',
                    arguments: ['welfare_center_648_gift', 'CLK', {}]
                  });
            }} to={"/game/project?id=" + gift?.id} className="more">
                更多
            </Link>
        </div>
        <div className="welfare-main">
          {gift.items?.map((game, index) => <div className={'project-item' + (index === 0 ? ' special' : '')}><Link to={'/game?id=' + game.game_id} >
              <div className="item-base">
              <div className="icon"><Image src={game.game_image.thumb}/></div>
              <div className="text">
                  <div className="name">
                    {/* {game.game_name} */}
                    <div className={" " + (game.nameRemark ? " name-new" : "")}>
                      {game.game_name}
                    </div>
                  </div>

                  <div className="info2">
                  {game.game_classify_type.trim()}
                  <Subtitle>{game.nameRemark}</Subtitle>
                  </div>
              </div>
              </div>
              </Link>
              <div>
                  <div className="item-gift-text">
                    <div className="text-title" style={{height:'5px'}}>
                      {/* {game.packname} */}
                    </div>
                    <div className="text-info">
                      <div className="info-content">
                        <div className="info-content-title">{game.packname}</div>
                        <div className="info-content-desc">{game.packcontent}</div>
                      </div>
                      <div className="info-btn">
                        {
                          game.isreceived ? <CopyToClipboard text={game.card} onCopy={() => Dialog.info('已复制')}>
                          <div className="btn-copy">复制</div>
                          </CopyToClipboard> :
                          // <div className="btn-receive" onClick={()=>receive_gift(game)}>
                          // 领取
                          // </div>
                          <div className="btn-receive" onClick={()=>{bouncesShow(game);setGamePubId(game.game_id)}}>
                          领取
                          </div>
                        }
                      </div>
                    </div>
                  </div>
              </div>
          </div>)}
        </div>
    </div>}

    {signgift?.items?.length > 0 && 
    <div className="welfare-project-items" style={{
              backgroundImage: 'url(/resources/welfare/welfare_item_bg.png)',
              backgroundRepeat: 'no-repeat',
              backgroundSize:'100% 23px'
            }}>
        <div className="project-items-title">
            <div className="plat-title title-img img">
                <img src="/resources/game/sign/签到礼包.png" />
            </div>
            
            <Link onClick={()=>{
              Manager.push({event_id:"A-3-13"})
              aplus_queue.push({
                    action: 'aplus.record',
                    arguments: ['welfare_center_648_gift', 'CLK', {}]
                  });
            }} to={"/signgift?id=" + gift?.id} className="more">
                更多
            </Link>
        </div>
        <div className="welfare-main">
          {signgift?.items?.map((game, index) => <div onClick={()=>{
            Manager.push({event_id:"A-3-13"})
          }} key={index} className={'project-item' + (index === 0 ? ' special' : '')}><Link to={'/game?id=' + game.appid} >
              <div className="item-base">
                <div className="icon"><Image src={game.game_image.thumb}/></div>
                <div className="text">
                    <div className="name">
                      {/* {game.game_name} */}
                      <div className={" " + (game.nameRemark ? " name-new" : "")}>
                        {game.gamename}
                      </div>
                    </div>

                    <div className="info2">
                    {game.game_classify_type.trim()}
                    <Subtitle>{game.nameRemark}</Subtitle>
                    </div>
                </div>
                <div className="info-btn">
                  {
                    game.isreceived ? <CopyToClipboard text={game.card} onCopy={() => Dialog.info('已复制')}>
                    <div className="btn-copy">复制</div>
                    </CopyToClipboard> :
                    <div className={"btn-receive " + (game.is_sign ? 'disable' : '')} onClick={(e)=>{
                      e.preventDefault()
                      e.stopPropagation()
                      bouncesShow(game);setGamePubId(game.game_id)
                      }}>
                    {game.is_sign ? '已签' : '签到'}
                    </div>
                  }
                </div>
              </div>
              </Link>
              <div>
                  <div className="item-gift-text">
                    <div className="text-title" style={{height:'5px'}}>
                      {/* {game.packname} */}
                    </div>
                    <NavLink to={`/gamesign?id=${game.maiyou_gameid}`} user={false} className="text-info">
                      <div className="info-content">
                        <div className="info-content-title">{game.packname}</div>
                        <div className="info-content-desc">{game.packcontent}</div>
                      </div>
                    </NavLink>
                  </div>
              </div>
          </div>)}
        </div>
    </div>}

    {visible && <SignAlert getContainer={document.getElementsByClassName('screen-index')[0]} gift={giftData} visible={visible} action={() => setVisible(false)}></SignAlert>}

    {active.map(item => <div className="welfare-active">
        <div className="plat-title title-img" style={{
          backgroundImage: 'url(/resources/welfare/welfare_item_bg.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize:'100% 23px'
        }}>
            <img src="/resources/welfare/热门活动.png" />
        </div>
        <div className="welfare-main">
            {(()=>{
              if(item.type == "special") {
                return <Link className="item"  to={'/game/project?id=' + item.id} onClick={()=>{
                  Manager.push({event_id:"A-3-14"})
                }}>
                  <div className="active-img">
                      <img src={item.cover} />
                  </div>
                  <div className="active-text">
                      {item.title}
                  </div>
                </Link>
              } else {
                return <NavLink className="item" to={item.url} iframe={{title: item.title}}>
                  <div className="active-img">
                      <img src={item.cover} />
                  </div>
                  <div className="active-text">
                      {item.title}
                  </div>
              </NavLink>
              }
            })()}
        </div>
    </div>)}

    
  </div>

  <CSSTransition in={giftShow} timeout={250} unmountOnExit={true}>
        <div className="gift-detail">
          <div className="gift-mask" onClick={() => setGiftShow(false)}/>
          <div className="gift-container">
            <div className="gift-top">
              <div className="icon">
              <Image src={giftDescInfoImage}/>
              </div>
              <div className="text">
                <div className="name">{giftDescInfo.gift_name}</div>
                <div className="time">有效期：{moment(giftDescInfo.gift_dealine * 1000).format('YYYY-MM-DD')}</div>
              </div>
            </div>
            <div className="gift-main">
              <div className="gift-desc" style={{borderBottom:"1px solid #ededed",padding:"0 0 10px 0"}}>
                <div className="title">礼包说明</div>
                <div className="main">{giftDescInfo.gift_desc}</div>
              </div>
              <div className="gift-desc" style={{borderBottom:"1px solid #ededed",padding:"10px 0"}}>
                <div className="title">使用说明</div>
                <div className="main">{giftDescInfo.gift_introduce}</div>
              </div>
            </div>
            <NavLink className="gift-bottom" onClick={() => receive_gift(giftDescInfo)}>领取</NavLink>
          </div>
        </div>
      </CSSTransition>

  {cdking ? <div className="welfare-cdk">
      <div className="mask" onClick={() => setCdking(false)}/>
      <div className="entity">
        <div className="title">
          <span>{alertType == 'activeGift' ? '活动礼包' : '有奖点评'}</span>
          <div className="close" onClick={() => setCdking(null)}><CloseOutlined style={{color:'#999'}}></CloseOutlined></div>
        </div>
        {alertType == 'activeGift' && <div className="info">兑换码来源：平台不定期举办活动获取！</div>}
        {alertType == 'activeGift' ? <div className="desc">
          <p>1.参加活动获取兑换码</p>
          <p>2.进入盒子金币页面，点击活动领取</p>
          <p>3.输入兑换码，点击确认</p>
          <p>4.领取成功，获得金币</p>
        </div> : 
        <div className="desc">
          <p dangerouslySetInnerHTML={{__html:data.goodsWelfare.filter(e=>e.name == 'awardedReview')[0].tips}}></p>
        </div>}
        {alertType == 'activeGift' && <div className="input">
          <input type="text" value={cdkCode} placeholder="请输入兑换码" autoComplete={false} onChange={e => setCdkCode(e.target.value)} onKeyUp={e => e.keyCode === 13 && cdkExchange()}/>
          <div className="btn" onClick={() => cdkExchange()}>确认</div>
        </div>}
      </div>
    </div> : null}

  {/***实名认证弹框***/}
  {
    isCheckAuth?
    <div className="pay-check-auth">
      <div className="auth-index">
        <div className="close" onClick={() => setIsCheckAuth(false)}>
          <img src="/resources/user/close.png" />
        </div>
        <div className="title">
          <div className="title-img">
            <img src="/resources/user/auth-title.png" />
          </div>
          <div>
            <h3>实名认证</h3>
            <p>
              {config.authTopTips}
              {/* 根据国家新闻出版署最新规定，游戏用户需要进行实名认证，否则我们无法为您提供游戏及支付服务！ */}
            </p>
          </div>
        </div>

        <div className="auth-user-realname">
          <div className="auth-user-input">
            <div className="name">真实姓名</div>
            <input name="nick_name" type="text" placeholder="请输入姓名" autocomplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
          </div>
          <div className="auth-user-input">
            <div className="name">身份证号</div>
            <input name="number" type="text" placeholder="请输入身份证号" autocomplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
          </div>
          <span>
            {config.authBottomTips}
          {/* 您所提交的身份信息仅用于国家认证，我们将按照国家隐私条例严格保密信息，感谢您的支持！ */}
          </span>
          <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
        </div>
      </div>
    </div>
    : null
    }

    {
      trumpetState ?
      <div className="game-trumpet-index">
        <div className="trumpet-site">
          <div className="trumpet-main">
              <div className="trumpet-title">
              温馨提示
              </div>
              <div className="info">
                <p>您游戏中尚未创建小号，暂时无法领取此礼包</p>
              </div>
              <div className="trumpet-btn">
                <div className="item btn-about" onClick={()=>{setTrumpetState(false)}}>
                  稍后再领
                </div>
                <NavLink to={"/game?id="+gamePubId+"&isExists=1"} className="item btn-confirm" onClick={()=>{setTrumpetState(false);setGiftShow(false);}}>
                  <h2>{giftDescInfo.type == 3 ? '进入游戏' : '下载游戏'}</h2>
                </NavLink>
              </div>
          </div>
        </div>
      </div>
      : null
    }
  </div>
};