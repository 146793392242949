import React, {useEffect, useRef, useState, useCallback, useLayoutEffect} from "react";
import {useLocalStorageState, useScroll, useThrottleFn, useLockFn, useMount} from "ahooks";
import general, {redux} from "../../general";
import {Swiper, SwiperSlide} from 'swiper/react';
// import {Link} from "react-router-dom";
import {Link, useHistory, useLocation} from "react-router-dom";
import {NavLink} from "../../lib/Navigation";
import moment from "moment";
import useActiveState from "../../lib/useActiveState";
import $ from "jquery";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import URI from "urijs";
import { unstable_concurrentAct } from "react-dom/cjs/react-dom-test-utils.production.min";
import { url } from "../../env";
import MobileDetect from "mobile-detect";
import SwiperCore, {Autoplay} from 'swiper';
import {EffectFade,Pagination} from 'swiper/core';
import "swiper/swiper.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import useUrlState from "@ahooksjs/use-url-state";
import {useUpdate} from "ahooks";
import CopyMask from '../mask/CopyMask'
import Subtitle from "../components/Subtitle";
import HomeBanner from "../components/Home/HomeBanner";
import HomeTopItem from "../components/Home/HomeTopItem";
import WantPlay from "../components/Home/WantPlay";
import Manager from "../components/Manager";
import AllSearch from "../components/Home/AllSearch";

SwiperCore.use([EffectFade,Pagination]);
const mobileDetect = new MobileDetect(navigator.userAgent);

const fixedMenus = [
  {
    id: 'card',
    text: '省钱卡',
    url: '/save?type=card',
    user: true,
  },
  {
    id: 'voucher',
    text: '免费领券',
    url: '/voucher',
    user: true,
  },
  {
    id: 'trade',
    text: '账号交易',
    url: '/trade',
    user: true,
  },
  // {
  //   id: 'server',
  //   text: '今日开服',
  //   url: '/server',
  //   user: false,
  // },
  /*{
    id: 'topic',
    text: '游戏圈子',
    url: '/topic',
    user: true,
  },*/
];
const swipers = [];
SwiperCore.use([Autoplay]);

export default (props) => {
  const {aplus_queue} = window;
  const history = useHistory();
  const location = useLocation();
  const update = useUpdate();
  // SwiperCore.use([Autoplay]);
  const [user] = useActiveState('user');
  const [config, setConfig] = useActiveState('config');
  const [agent] = useActiveState('agent');
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [banners, setBanners] = useLocalStorageState('good-banner', []);
  const [menus, setMenus] = useLocalStorageState('good-menu', fixedMenus);
  // const [datas, setDatas] = useLocalStorageState('good-data', []);

  const [datas, setDatas] = useState([]);
  const [goodDatas, setGooDatas] = useLocalStorageState('good-data', []);//这俩是首页专题缓存的测试

  const [whether, setWhether] = useActiveState('whether');//是否进行过退出操作
  const [firstChargeState, setFirseChargeState] = useState(false); //首充弹框状态
  const [packageGiftState, setPackageGiftState] = useState(false); //代金券弹框状态
  const [noLoginPop, setNoLoginPop] = useState(false); //未登录弹框的状态
  const [isAccord, setIsAccord] = useState(false); //是否显示
  const [noLoginDatePop, setNoLoginDatePop] = useLocalStorageState('nologin-date-pop', []); //未登录每日的状态
  const [firstDatePop, setFirstDatePop] = useLocalStorageState('first-date-pop', []); //首充每日弹框状态
  const [packDatePop, setPackDatePop] = useLocalStorageState('pack-date-pop', []); //代金券每日弹框状态
  const [betweenEigthDay, setBetweenEigthDay] = useLocalStorageState('eigth-date-pop', []); //代金券超过30天标识
  const [indexActiveState, setIndexActiveState] = useState(false);
  const [isIndexActivityDatePop, setIsIndexActivityDatePop] = useLocalStorageState('index-activity', []); //活动的每日弹框状态
  const [isIndexNoticeDatePop, setIsIndexNoticeDatePop] = useLocalStorageState('index-notice', []); //活动的每日弹框状态
  const [downBoxState, setDownBoxState] = useState(true);
  // const [popularGame, setPopularGame] = useLocalStorageState('popular-banner', []);
  // const [copyright] = useLocalStorageState('copyright', []);
  const [copyright] = useActiveState('copyright');
  const [app] = useActiveState('app');
  // const [datasData] = useLocalStorageState('users');
  const [activeInfo, setActiveInfo] = useActiveState('activeInfo');
  const [testConfig, setTestConfig] = useLocalStorageState('testConfig', []);
  // const [newMenus, setNewMenus] = useLocalStorageState('new-good-menu', []);

  const [newMenus, setNewMenus] = useState([]);
  const [newMenusCache, setNewMenusCache] = useLocalStorageState('new-good-menu', []);//导航缓存的两个字段

  // const [newBanners, setNewBanners] = useLocalStorageState('new-goos-banner', []);
  const [newBanners, setNewBanners] = useState([]);
  const [newGameInfoDetail, setGameInfoDetail] = useLocalStorageState('new-game-info-detail', []);
  const [copyPopState] = useActiveState('copyPopState');
  // const [placeholderAgent] = useActiveState('placeholderAgent');

  const [spweardRecord, setSpweardRecord] = useLocalStorageState('spweard-state', 0);//记录首页推广专题的弹框是否弹过
  const [spweardState, setSpweardState] = useState(false); //首页推广专题的弹框
  const [gamePopRecord, setGamePopRecord] = useLocalStorageState('gamepop-state', 0);//记录首页推广游戏的弹框是否弹过
  const [gamePopState, setGamePopState] = useState(false); //首页推广专题的弹框
  const [cqweardRecord, setCqweardRecord] = useLocalStorageState('cqweard-state', 0);//记录首页传奇是否跳转过
  const [bgameweardRecord, setBgameweardRecord] = useLocalStorageState('bgameweard-state', 0);//记录首页是否跳转过

  const [randomColorInfo] = useActiveState('randomColorInfo');
  const [infoTextColor, setInfoTextColor] = useState();
  let [mb, setMb] = useActiveState('mb');
  const [alertPicCompletion, setAlertPicCompletion] = useState(false);
  const [likeTags] = useActiveState('likeTags');

  //恢复离开时的位置 start 20230519
  const { pathname } = location;
  const scrollTo = () => {
    // console.log('location');
    // console.log(location);
    // console.log('pathname');
    // console.log(pathname);
    const scrollY = sessionStorage.getItem(pathname);
    // 为了避免scrollY为0引起的滚动到顶部后没有记录的bug，将交互改为在150px内直接滚动到顶部
    if (scrollY) {
      const scrollResult = Number(scrollY) < 150 ? 0 : Number(scrollY);
      window.scrollTo(0, scrollResult);
    }
    // console.log('scrollY');
    // console.log(scrollY);
  };
  //恢复离开时的位置 end 20230519

  //判断是否在数组中
  // console.log(placeholderAgent.includes(agent));

  const load = () => {
    if (loading) {
      return;
    }
    if(Object.keys(newMenusCache).length > 0) {
      if (init){
        setLoading(true);
        Dialog.loading();
      }
    }else{
      setLoading(true);
      Dialog.loading();
    }
    general.axios.get('/getHomeData')
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        setNewMenus(data.nav);
        setBanners([data.banner]);
        setDatas(data.list);

        setTimeout(()=>{
          setNewMenusCache(data.nav);
          setGooDatas(data.list);
        }, 5000);

        setInfoTextColor(randomColorInfo[parseInt(Math.random() * 4)]);

        // if(config.is_activity == '1') {
        //   active_load();
        // }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  // useEffect(() => {
  //   if (config.index_menu_items) {
  //     let menus = [...fixedMenus];
  //     config.index_menu_items.forEach((item, index) => {
  //       if (item.is_show) {
  //         menus.splice(1, 0, {
  //           id: index + 1,
  //           text: item.title,
  //           url: '/game/project?id=' + item.id,
  //         });
  //       }
  //     });
  //     setMenus(menus);
  //   }
  // }, [config.index_menu_items, config.index_menu_item1_start_date, config.index_menu_item1_end_date]);
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  const scroll = useScroll();
  useLayoutEffect(() => {
    if (props.active) {
      scrollTo();
      // console.log("进来了 ");
      // console.log(scroll.top);
      if(Object.keys(newMenusCache).length > 0 && newMenus?.length == 0) {
        setNewMenus(newMenusCache);
      }

      if(Object.keys(goodDatas).length > 0 && datas?.length == 0) {
        setDatas(goodDatas);
      }
    }else{
      const top = scroll.top
      sessionStorage.setItem(pathname, String(top));
    }
  }, [props.active]);

  useEffect(() => {
    if(whether == 'true') {
      load();
      setWhether('false');
    }
    // load();
  }, [user]);
  useEffect(() => {
    if(whether == 'true' && init) {
      load();
    }
  }, [whether,init])
  useEffect(() => {
    load();
  }, [likeTags])
  const {run: setOpacity} = useThrottleFn(value => props.setOpacity(value), {wait: 10});
  
  useEffect(() => {
    let opacity;
    if (props.active) {
      // console.log(scroll.top);
      if (scroll.top > 0) {
        opacity = Math.abs(scroll.top) / 200;
        // console.log(opacity);
        if (opacity > 1) {
          opacity = 1;
        }
      } else {
        opacity = 0;
      }
    }
    setOpacity(opacity);

    if (props.active && init) {
      if (scroll.top < -80) {
        load();
      }
    }

    //记录离开的位置
    // sessionStorage.setItem(pathname, String(window.scrollY));
  }, [scroll, props.active, init]);

  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    swiper && swiper.update();
  }, [props.active, swiper, banners]);

  const [banner, setBanner] = useState(null);
  const menuRef = useRef(null);
  useEffect(() => {
    if (menuRef.current && props.active && !init) {
      setTimeout(() => {
        $(menuRef.current).animate({scrollLeft: menuRef.current.offsetWidth}, 1000, null, () => {
          $(menuRef.current).animate({scrollLeft: 0}, 1000);
        });
      }, 250);
    }
  }, [props.active, init]);
  const menuScroll = useScroll(menuRef);
  const scrollbarRef = useRef(null);
  // useEffect(() => {
  //   if (menuRef.current && scrollbarRef.current) {
  //     if (menuRef.current.scrollWidth <= $(menuRef.current).width()) {
  //       $(scrollbarRef.current).parent().hide();
  //     } else {
  //       $(scrollbarRef.current).css({left: (menuScroll.left + $(menuRef.current).width() / 2) / menuRef.current.scrollWidth * 100 + '%'});
  //       // $(scrollbarRef.current).css({width: $(menuRef.current).width() / menuRef.current.scrollWidth * 100 + '%'});
  //       $(scrollbarRef.current).css({width: '50%'});
  //       // console.log($(menuRef.current).width());
  //       // console.log(menuRef.current.scrollWidth);
  //     }
  //   }
  // }, [menuScroll]);

  useEffect(() => {
    if (menuRef.current && scrollbarRef.current) {
      // console.log($(menuRef.current).width());
      // console.log(menuScroll.left);

      // console.log($(menuRef.current).width());//一屏占比

      // console.log(menuRef.current.scrollWidth);//全部占比

      // console.log(newMenus.length);

      // console.log(newMenus.length / 5);

      // var pages=newMenus.length%5==0?(newMenus.length/5):(Math.floor(newMenus.length/5)+1);

      // console.log(pages);

      // console.log(menuScroll.left);

      // console.log(menuRef.current.scrollWidth);

      // console.log( $(menuRef.current).width());

      // console.log( menuScroll.left / ((menuScroll.left / (menuRef.current.scrollWidth / newMenus.length)) + 0.1)  + '%' );


      $(scrollbarRef.current).css({left: (menuScroll.left + $(menuRef.current).width() / 2) / menuRef.current.scrollWidth * 100 + '%'}); //原来的
      $(scrollbarRef.current).css({width: '50%'});//原来的

      // $(scrollbarRef.current).css({left: 25 * ((menuScroll.left / (menuRef.current.scrollWidth / newMenus.length)) + 1) + '%'});
      // $(scrollbarRef.current).css({width: $(menuRef.current).width() / menuRef.current.scrollWidth * 100 + '%'});
    }
  }, [menuScroll, init]);

  useEffect(() => {
    props.active && swipers.forEach(swiper => swiper.update());
  }, [props.active, datas]);
  
  // console.log(popularGame);
  // console.log(popularGame.activity_top.length);

  const closePop = useLockFn(() => {
    Manager.push({event_id:"B-8"})
    let asIs = user.member_id ?? localStorage.getItem('user_id');
    let today = asIs + moment().format('YYYY-MM-DD');

    if(indexActiveState) {
      let active_day = 'active_' + today;
      setIsIndexActivityDatePop(active_day);
      setIndexActiveState(false);
      ClickPopUpAds();
    }

    //代金券弹框
    if(packageGiftState) {
      let pack_day = 'pack_' + today;
      setPackDatePop(pack_day);
      setPackageGiftState(false);
      // setFirstDatePop(null);
      // setFirseChargeState(false);
      user.is_show_reg_between_8day_30day ? setBetweenEigthDay(1) : null;
    }
    
    //送首充弹框
    if(firstChargeState) {
      let first_day = 'first_' + today;
      setFirstDatePop(first_day);
      setFirseChargeState(false)
    }
    // console.log(noLoginPop);
    //未登录时的弹框
    if(noLoginPop) {
      let nologin_day = 'nologin_' + moment().format('YYYY-MM-DD');
      setNoLoginDatePop(nologin_day);
      setNoLoginPop(false);
    }
  })
  // console.log(isIndexActivityDatePop);
  const judge = useLockFn(() => {
  // const judge = () => {
    let asIs = user.member_id ?? localStorage.getItem('user_id');
    // if((Object.keys(user).length > 1 || localStorage.getItem('username')) && isIndexActivityDatePop !== 'active_' + asIs + moment().format('YYYY-MM-DD') && ( ((mobileDetect.is('iPhone') || mobileDetect.is('iPad')) && window.navigator.standalone) || (!mobileDetect.is('iPhone') && !mobileDetect.is('iPad') ) ) ) {
    if(isIndexActivityDatePop !== 'active_' + asIs + moment().format('YYYY-MM-DD') && ( ((mobileDetect.is('iPhone') || mobileDetect.is('iPad')) && window.navigator.standalone) || (!mobileDetect.is('iPhone') && !mobileDetect.is('iPad') ) ) ) {
      if(config.is_activity == '1') {
        setIndexActiveState(true);

        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['ShowPop-upAds', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), activename:activeInfo.name, agent: agent}]
        });
      } else {
        setIndexActiveState(false);
      }
    }

    if ((Object.keys(user).length > 0 || localStorage.getItem('username')) && indexActiveState == false && packDatePop !== 'pack_' + asIs + moment().format('YYYY-MM-DD') && ( ((mobileDetect.is('iPhone') || mobileDetect.is('iPad')) && window.navigator.standalone) || (!mobileDetect.is('iPhone') && !mobileDetect.is('iPad') ) ) ) {
      if(user.novice_fuli_v2101_show || user.is_show_reg_between_8day_30day) {
        // setPackageGiftState(true);
      } else if (user.is_show_reg_gt_30day == true) {
        if(betweenEigthDay !== '1') {
          // setPackageGiftState(true);
        }
      }
    } else if ((Object.keys(user).length > 0 || localStorage.getItem('username')) && firstDatePop !== 'first_' + asIs + moment().format('YYYY-MM-DD') && packageGiftState == false && indexActiveState == false && ( ( (mobileDetect.is('iPhone') || mobileDetect.is('iPad')) && window.navigator.standalone) || (!mobileDetect.is('iPhone') && !mobileDetect.is('iPad') ) ) ){
      if(user.is_finish_newtask) {
        setFirseChargeState(false);
      } else {
        if(testConfig.is_activity != '1') {
          setFirseChargeState(true); 
        }
      }
    }
    // if(user.isLogin === false && window.navigator.standalone && noLoginDatePop !== 'nologin_' + user.member_id + moment().format('YYYY-MM-DD')) {
    if((Object.keys(user).length === 0) && noLoginDatePop !== 'nologin_' + moment().format('YYYY-MM-DD') && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS') && packageGiftState == false && indexActiveState == false && firstChargeState == false) {
      setNoLoginPop(true);
    }
  })
  
  const active_load = () => {
    general.axios.get('/base/activity/getActivity')
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        // console.log(testConfig.is_activity);
        // console.log(indexActiveState);
        // console.log(isIndexActivityDatePop);
        // setTestConfig({...testConfig, ...config});
        setActiveInfo(data);
        let asIs = user.member_id ?? localStorage.getItem('user_id');
        // if ((Object.keys(user).length > 1 || localStorage.getItem('username')) && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')){
        if (!indexActiveState && isIndexActivityDatePop != 'active_' + asIs + moment().format('YYYY-MM-DD') ){
        // if ((Object.keys(user).length > 1 || localStorage.getItem('username')) && !indexActiveState && isIndexActivityDatePop != 'active_' + asIs + moment().format('YYYY-MM-DD') && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')){
          setIndexActiveState(true);
        }

        // setPackageGiftState(false);
        // setFirseChargeState(false);
        // setNoLoginPop(false);
        
        setTimeout(() =>{setIsAccord(true)}, 1000);
        setTimeout(() =>{judge()}, 1000);
      })
      .catch(err => {
        
      });
  }

  const qq_config = () => {
    general.axios.get('/base/common/getConfig')
      .then(res => {
        let {status, data} = res.data;
        if (status.succeed) {
          setTestConfig({...testConfig, ...data});
          if(data.is_activity == '1') {
            active_load();
          } else {
            setTimeout(()=>{setIsAccord(true)}, 1000);
            setTimeout(() =>{judge()}, 1000);
          }
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      })
  }

  // useEffect(() => {
  //   qq_config()
  // }, [app, user.member_id, props.active, config.is_activity]);

  useEffect(() => {
    if(config.is_activity == '1' && likeTags == '1') {
      active_load();
    }
    // qq_config()
  }, [config.is_activity, props.active, user, likeTags]);

  const [browserState, setBrowserState] = useState(false);
  const [browserUrlText, setBrowserUrlText] = useState();
  const [browserCopys, setBrowserCopys] = useState(false);
  const [isUnfreeze, setIsUnfreeze] = useState(true);//copy状态
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});

  //阻止滑动
  const ref = useCallback(node => {
    if (node) {
      node.addEventListener('touchmove', e => {
        e.preventDefault();
      }, {passive: false});
    }
  }, []);

  const verdict = () => {
    // let url = "https://" + window.location.host + "/#/";
    // setBrowserUrlText(url);
    if(mobileDetect.is('iPhone') || mobileDetect.is('iPad')) {
      if(mobileDetect.userAgents() != 'Safari') {
        if(copyPopState == false) {
          // let url = "https://" + window.location.host + "/#/";
          let url = window.location.href;
          setBrowserUrlText(url);
          setBrowserState(true);
        }
        // if(agent != '3b8n' && agent != "1eaz" && agent != "m88y" && agent != '91xd') {
        //   let url = "https://" + window.location.host + "/#/";
        //   setBrowserUrlText(url);
        //   setBrowserState(true);
        // }
      }
    }
  }

  useEffect(()=>{
    general.axios.post('/base/newindex/getAgentState', {agent:agent})
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }
      
      setIsUnfreeze(data);

      if(data == true) {
        setBrowserState(false);
        setBrowserCopys(false);
      } else {
        // verdict();
        if(mobileDetect.is('iPhone') || mobileDetect.is('iPad')) {
          if(mobileDetect.userAgents() != 'Safari') {
            if(data == false) {
              let url = "https://" + window.location.host + "/#/";
              if(params.agent) {
                url = window.location.href;
              } else {
                url = window.location.href + "&agent=" + agent;
              }
              if(mb != 1) {
                setBrowserUrlText(url);
                setBrowserState(true);
              }
            }
          }
        }

      }
    })
    .catch(err => {
      Dialog.error(err.message);
    });

    // if(isUnfreeze == true) {
    //   setBrowserState(false);
    //   setBrowserCopys(false);
    // } else {
    //   verdict();
    // }
    // if(agent == '3b8n' || agent == "1eaz" || agent == 'm88y' || agent == '91xd') {
    //   setBrowserState(false);
    //   setBrowserCopys(false);
    // } else {
    //   verdict();
    // }
  }, [agent]);

  const [tradesStatePop, setTradesStatePop] = useState(false);
  const [tradesReject, setTradesReject] = useState({});

  const [tradeGamePop, setTradeGamePop] = useState(false);
  const [tradeNotice, setTradeNotice] = useState([]);

  const [alertList, setAlertList] = useState([]);
  const [alertInit, setAlertInit] = useState(false);

  const [bottomGame, setBottomGame] = useLocalStorageState('bottom-banner', []);
  const [bottomBannerState, setBottomBannerState] = useState(false);
  // const [recommendTop, setRecommendTop] = useLocalStorageState('recommend-top', []);
  const [recommendTop, setRecommendTop] = useState({});
  const [popularGame, setPopularGame] = useState([]);

  useEffect(() => {
    // let data = {};
    // if(user.member_id){
    //   data.member_id = user.member_id;
    // }

    if (!props.active) {
      return;
    }

    // if(!general.isLogin()) {
    //   return;
    // }

    if(!general.isLogin()) {
      setPopularGame([]);
      setBottomGame([]);
      setBottomBannerState(false);
      // return;
    }

    if(props.active && !alertInit && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')) {
    // if(props.active && !alertInit) {
      noticeAlert();
    }

    // let asIs = user.member_id ?? localStorage.getItem('user_id');
    // let today = asIs + moment().format('YYYY-MM-DD');
    // let notice_day = 'notice_' + today;

    // general.axios.post('/base/common/notice')
    // .then(res => {
    //   let {status, data} = res.data;
    //   if (!status.succeed) {
    //     Dialog.error(status.error_desc);
    //     return;
    //   }
      
    //   if(Object.keys(data.reject).length > 0) {
    //     if(data.reject.type) {
    //       setTradesReject(data.reject)
    //       setTradesStatePop(true)
    //     }
    //   }

    //   if(Object.keys(data.notice).length > 0 && isIndexNoticeDatePop !== notice_day && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS') && config?.is_activity != '1') {
    //     if(data.notice.type == 2) {
    //       if(data.notice.link_info.link_route == 'game_info' || data.notice.link_info.link_route == 'outer_web') {
    //         setTradeNotice(data.notice);
    //         setTradeGamePop(true);
    //       }
    //     }
    //   }
    // })
    // .catch(err => {
    //   Dialog.error(err.message);
    // });
  }, [props.active, alertInit]);

  useEffect(() => {
    // if(!general.isLogin()) {
    //   setPopularGame([]);
    //   setBottomGame([]);
    //   setBottomBannerState(false);
    //   // return;
    // }

    let asIs = user.member_id ?? localStorage.getItem('user_id');
    let today = asIs + moment().format('YYYY-MM-DD');
    let notice_day = 'notice_' + today;

    // if(isIndexNoticeDatePop !== notice_day && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')) {
      
    // if(!alertInit && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')) {
    //   noticeAlert();
    // }
  }, [user]);

  const noticeAlert = () => {
    let asIs = user.member_id ?? localStorage.getItem('user_id');
    let today = asIs + moment().format('YYYY-MM-DD');
    let notice_day = 'notice_' + today;

    general.axios.post('/base/common/notice')
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }

      if(general.isLogin()) {
        if(data.alertList?.length > 0) {
          let alertlist = data.alertList.map(item => ({...item, display: true}));
          setAlertList(alertlist);
        }
        setAlertInit(true);

        let asIs = user.member_id ?? localStorage.getItem('user_id');
        let today = asIs + moment().format('YYYY-MM-DD');
        let notice_day = 'notice_' + today;

        setIsIndexNoticeDatePop(notice_day);
      }
      //横幅活动
      let popular = data.popularGame ? (data.popularGame.activity_top.length == 0 ? '' : data.popularGame.activity_top) : '';
      setPopularGame(popular);

      if(general.isLogin()) {
        if(mobileDetect.os() != 'AndroidOS') {
          setBottomGame(data.popularGame ? (data.popularGame.bottom_banner ? data.popularGame.bottom_banner : '') : '')
          setBottomBannerState(true);
        }

        // console.log(data.popularGame.recommend_top);
        //重磅推荐
        setRecommendTop(data.popularGame ? (data.popularGame.recommend_top ? data.popularGame.recommend_top : '') : '');
      }
      
      // if(Object.keys(data.reject).length > 0) {
      //   if(data.reject.type) {
      //     setTradesReject(data.reject);
      //     setTradesStatePop(true);
      //   }
      // }

      // if(Object.keys(data.notice).length > 0 && isIndexNoticeDatePop !== notice_day && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')) {
      //   if(data.notice.type == 2) {
      //     if(data.notice.link_info.link_route == 'game_info' || data.notice.link_info.link_route == 'outer_web') {
      //       setTradeNotice(data.notice);
      //       setTradeGamePop(true);
      //     }
      //   }
      // }

    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  const closeTradePop = () => {
    setTradeGamePop(false);

    let asIs = user.member_id ?? localStorage.getItem('user_id');
    let today = asIs + moment().format('YYYY-MM-DD');
    let notice_day = 'notice_' + today;

    setIsIndexNoticeDatePop(notice_day);
  }

  const closeNewTradePop = item => {
    item.display = false;
    update()
  }

  const newoper = e => {
    if(e.jumpType == 'outer_web') {
      window.open(e.value, "_blank");
    } else if(e.jumpType == 'inner_web') {
      let url = URI(e.value)
        .addQuery('username', localStorage.getItem('username'))
        .addQuery('token', localStorage.getItem('token'))
        .addQuery('location_url', window.location.origin)
        .addQuery('origin', 'react')
        .toString();
      window.open(url, "_blank");
    } else {
      // history.push("/game?id=" + e.link_info.link_value);
    }
  }

  const onProgress = (swiper, progress) => {
    for(let i = 0; i < swiper.slides.length; i++){
      let slide = swiper.slides.eq(i);
      let slideProgress = swiper.slides[i].progress
      let modify = 1;
      if (Math.abs(slideProgress) > 1) {
          modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
      }
      let translate = slideProgress * modify * 180 + 'px';
      let scale = 1 - Math.abs(slideProgress) / 3;
      let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
      slide.transform('translateX(' + translate + ') scale(' + scale + ')');
      slide.css('zIndex', zIndex);
      slide.css('opacity', 1);
      if (Math.abs(slideProgress) > 3) {
          slide.css('opacity', 0);
      }
    }
  }

  const onSetTransition = (swiper, transition) => {
    for (let i = 0; i < swiper.slides.length; i++) {
        let slide = swiper.slides.eq(i)
        slide.transition(transition);
    }
  }

  // console.log(user);
  const [addMarks, setAddMarks] = useLocalStorageState('is-add-marks', 0);//添加书签统计标识

  useEffect(() => {
    if(props.active) {
      // console.log("111");
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['HomeViewAppear', 'CLK', {age: user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), agent: agent}]
      });

      if(addMarks == 0 && window.navigator.standalone) {
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['NewUserOfBookmark', 'CLK', {agent:agent}]
        });
        setAddMarks(1);
      }
    }
  }, [props.active]);

  useEffect(() => {
    if(spweardRecord == 0) {
      if(params.zt && params.zu && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')) {
        setSpweardRecord(1);
        setSpweardState(true);
      }
    }

  }, [params.zt, params.zu]);

  useEffect(() => {
    if(cqweardRecord == 0) {
      if(params.ct && params.cu && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')) {
        setCqweardRecord(1);
        redux.dispatch({
          type: 'type',
          data: {
            screenRouter: {
              screen: '/game',
              type: 0,
              tag: params.cu,
            },
          }
        });
      }
    }

  }, [params.ct, params.cu]);

  useEffect(() => {
    if(window.navigator.standalone === true && bgameweardRecord == 0) {
      if(init && params.b) {
        setTimeout(() => {
          setBgameweardRecord(1);
          history.push('/game?id=' + params.b + '&indexsource=index');
        }, 1000);
      }
    }

  }, [params.b, init]);

  // useEffect(() => {
  //   if(gamePopRecord == 0) {
  //     if(params.b && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')) {
  //       setGamePopRecord(1);
  //       setGamePopState(true);
  //     }
  //   }
  // }, [params.b]);

  // let history = useHistory();
  // let location = useLocation();
  // const {
  //     global: { current, scrollData, changeParams },
  // } = store;
  // useEffect(() => {
  //     const { pathname, search } = location;
  //     changeParams({
  //         current: decodeURIComponent(pathname + search)
  //     })
  //     if (!history._listenCount) {
  //         history._listenCount++;
  //         history.listen((locationState, type) => {
  //             const {
  //                 global: { current, scrollData, changeParams },
  //             } = store;
  //             let node = document.getElementsByClassName('ant-layout-content')[0]
  //             if (!node) {
  //                 return
  //             }
  //             // node.scrollTop = 0;
  //             //最新页面的Key，存上就行
  //             let newkey = decodeURIComponent(locationState.pathname + locationState.search);
  //             /* 找一下看看有没有记录值 */
  //             let recordValue = scrollData[newkey]
  //             if (typeof recordValue === 'number') {
  //                 scrollToView(node, recordValue)
  //             }
  //             // 取值（过去的）存一下，old
  //             let oldKey = current;
  //             let scrollDataTemp = { ...toJS(scrollData), [oldKey]: node.scrollTop }
  //             if (type === "POP") {
  //                 //POP 情况下node.scrollTop已经被设置成0了
  //                 changeParams({
  //                     current: newkey,
  //                 })
  //             } else {
  //                 changeParams({
  //                     current: newkey,
  //                     scrollData: scrollDataTemp
  //                 })
  //             }
  //         })
  //     }
  // }, []);
  
  //首页banner点击埋点
  const bannerFeatured = (banner) => {
    let game_name = '';
    let active_name = '';

    if(banner.type === 'activity' || banner.type === 'holidayEvent') {
      active_name = '活动';
    } else if(banner.type == 'game_info') {
      game_name = banner.gameinfo.game_name;
    }

    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickHomeFeaturedBanner', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename:game_name, activename:active_name, agent: agent}]
    });
  }

  //首页广告位 点击埋点
  const pageActivityBanner = (e) => {
    Manager.push({event_id:e.event_id})
    let active_name = '';
    if(e.type === 'inner_web' || e.type === 'onner_web') {
      active_name = e.title;
    }
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickHomePageActivityBanner', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename:'', activename:active_name, agent: agent}]
    });
  }

  //首页icon点击埋点
  const clickIconOfHomePage = (menu) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickIconOfHomePage', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), iconname:menu.title, agent: agent}]
    });
  }

  //首页活动弹框点击埋点
  const ClickPopUpAds = () => {
    Manager.push({event_id:"B-8"})
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickPop-upAds', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), activename:activeInfo.name, agent: agent}]
    });
  }

  //首页专题游戏点击埋点
  const clickTheGameInTheTopic = (item) => {
    Manager.push({event_id:item.event_id})
  }

  //首页专题详情埋点
  const clickTheTopicToSeeMore = (item) => {
    Manager.push({event_id:item.event_id})
  }

  const bottomOper = e => {
    if(e.is_check_login) {
      if(!general.isLogin()) {
        PubSub.publish('alert-login',true)
        return;
      }
    }
    if(e.type == 'work_weixin') {
      window.open(e.value, "_blank");
    } else if(e.type == 'game_info') {
      history.push('/game?id='+e.value);
    } else if(e.type == 'special') {
      history.push('/game/project?id='+e.value);
    } else if(e.type == 'news_info') {
      history.push('/message/detail?id='+e.value);
    } else if(e.type == 'inner_web' || e.type == 'outer_web') {
      window.open(e.value + "?username="+localStorage.getItem('username') + "&token="+localStorage.getItem('token'), "_blank");
    }
  }

  useEffect(()=>{
    //活动跳转新的平台币
    window.addEventListener('message', function(event) {
      var result = event.data;
      if(result?.type) {
        if(result.type == 'nisptb') {
          let url = URI("https://sys.wakaifu.com/home/MixPay/ptb")
                .addQuery('username', localStorage.getItem('username'))
                .addQuery('token', localStorage.getItem('token'))
                .addQuery('location_url', window.location.origin)
                .addQuery('mb', localStorage.getItem('mb'))
                .addQuery('agent', agent)
                .addQuery('from', 'react')
                .toString();
          
          // let url = "https://sys.wakaifu.com/home/MixPay/ptb?username="+localStorage.getItem('username')+"&token="+localStorage.getItem('token')+"&location_url=" + window.location.origin +"&agent="+agent+"&from=react&title=充值";
          window.location.href = '#/iframe?url=' + encodeURIComponent(url) + '&title=充值&sinfo=nisptb';
        } else if(result.type == 'flexible') {//活动跳转
          let url = URI(result.active_url)
                .addQuery('username', localStorage.getItem('username'))
                .addQuery('token', localStorage.getItem('token'))
                .addQuery('location_url', window.location.origin)
                .addQuery('agent', agent)
                .addQuery('from', 'react')
                .toString();

          window.location.href = '#/iframe?url=' + encodeURIComponent(url) + '&title=' + result.active_title;
        }
      }
    });
  });

  return <div className="index-good">

    <div className="">
      <div className="index-allban">
        <div className="index-banners">
          {/* <div className="bg" style={{backgroundImage: 'linear-gradient('+newBanners.color+', '+newBanners.color+', #f5f6f8)'}}/> */}
          {/* <div className="bg" style={{backgroundImage: 'linear-gradient(rgb(70,72,147), rgb(70,72,147), #f5f6f8)'}}/> */}
          {banners.length > 0 && <div className="bg" style={{backgroundImage: 'linear-gradient(' + (banners[0].color) + ', #f5f6f8)'}}/>}
          {banners.length > 0 && <HomeBanner event_id='B-1' banner={banners[0]}></HomeBanner>}

          <div className="index-menus" style={{display:(newMenus.length > 0 ? 'none' : 'none'), backgroundImage: 'linear-gradient(rgb(245, 246, 248,0),rgba(245, 246, 248, 0.6),rgb(245, 246, 248,0.9),rgb(245, 246, 248),rgb(245, 246, 248))'}}>
            <div className="container" ref={menuRef}>
            {/* agent == 'vw9d' && menu.jumpType == 'tradeGoods' ? '' :  */}
              {newMenus?.map((menu,index) => <div key={index} className="menu">
                {(()=>{
                  if(menu.jumpType == 'webview') {
                    return <NavLink  to={(()=>{
                      let url = URI(menu.url)
                      .addQuery('username', localStorage.getItem('username'))
                      .addQuery('token', localStorage.getItem('token'))
                      .addQuery('mb', localStorage.getItem('mb'))
                      .addQuery('location_url', window.location.origin)
                      .addQuery('origin', 'react')
                      .addQuery('uid', localStorage.getItem('user_id'))
                      .toString();
                      return url;
                    })()} iframe={{title:menu.browserTitle}} user={menu.isCheckLogin == 1?true:false} onClick={()=>clickIconOfHomePage(menu)}>
                      <div className="icon"><Image src={menu.icon} alt=""/></div>
                      <div className="name">{menu.title}</div>
                    </NavLink>
                  } else if(menu.jumpType == 'syGift'){
                    return <NavLink  to={(()=>{
                      let url = URI(menu.url)
                      .addQuery('username', localStorage.getItem('username'))
                      .addQuery('token', localStorage.getItem('token'))
                      .addQuery('mb', localStorage.getItem('mb'))
                      .addQuery('location_url', window.location.origin)
                      .addQuery('origin', 'react')
                      .addQuery('uid', localStorage.getItem('user_id'))
                      .toString();
                      return url;
                    })()} iframe={{title:menu.title}} user={menu.isCheckLogin == 1?true:false} onClick={()=>clickIconOfHomePage(menu)}>
                      <div className="icon"><Image src={menu.icon} alt=""/></div>
                      <div className="name">{menu.title}</div>
                    </NavLink>
                  } else if(menu.jumpType == 'active_novice'){
                    return <NavLink  to={(()=>{
                      let url;
                      if(menu.version == 1) {
                        url = URI(menu.url)
                        .addQuery('username', localStorage.getItem('username'))
                        .addQuery('token', localStorage.getItem('token'))
                        .addQuery('mb', localStorage.getItem('mb'))
                        .addQuery('location_url', window.location.origin)
                        .addQuery('origin', 'react')
                        .addQuery('uid', localStorage.getItem('user_id'))
                        .toString();
                      } else {
                        url = '/party/couple';
                      }
                      return url;
                    })()} iframe={(()=>{
                      if(menu.version == '1') {
                        return {title: menu.browserTitle};
                      } else if(menu.version == '2') {
                        return false;
                      }
                    })()} user={menu.isCheckLogin == 1 ? true:false} onClick={()=>clickIconOfHomePage(menu)}>
                      <div className="icon"><Image src={menu.icon} alt=""/></div>
                      <div className="name">{menu.title}</div>
                    </NavLink>
                  } else if(menu.jumpType == 'active_flyback'){
                    return <NavLink  to={(()=>{
                      let url;
                      if(menu.version == '1') {
                        url = URI(menu.url)
                        .addQuery('username', localStorage.getItem('username'))
                        .addQuery('token', localStorage.getItem('token'))
                        .addQuery('mb', localStorage.getItem('mb'))
                        .addQuery('location_url', window.location.origin)
                        .addQuery('origin', 'react')
                        .addQuery('uid', localStorage.getItem('user_id'))
                        .toString();
                        return url;
                      } else if(menu.version == '2'){
                        url = '/party/regression';
                        return url;
                      }
                    })()} iframe={(()=>{
                      if(menu.version == '1') {
                        return {title: menu.browserTitle};
                      } else if(menu.version == '2') {
                        return false;
                      }
                    })()} user={menu.isCheckLogin == 1?true:false} onClick={()=>clickIconOfHomePage(menu)}>
                      <div className="icon"><Image src={menu.icon} alt=""/></div>
                      <div className="name">{menu.title}</div>
                    </NavLink>
                  } else {
                    return <NavLink  to={(()=>{
                      if(menu.jumpType == 'mcard') {
                        // return '/save?type=card';
                        return '/save/newcard';
                      } else if(menu.jumpType == 'rechargeVip'){
                        // return '/save?type=vip';
                        return 'save/newvip';
                      } else if(menu.jumpType == 'freeReceiveVoucher') {
                        return '/voucher';
                      } else if(menu.jumpType == 'special') {
                        if(menu.style == '6') {
                          return "/party/beta?id=" + menu.id;
                        } else {
                          return '/game/project?id=' + menu.id;
                        }
                      } else if(menu.jumpType == 'trade') {
                        return '/trade';
                      } else if(menu.jumpType == 'openServer') {
                        return '/server';
                      } else if(menu.jumpType == 'goldMall') {
                        return '/shop';
                      } else if(menu.jumpType == 'share') {
                        return config?.inviteFriendsType == 2 ? "/newinvite" : "/invite";
                        // return '/invite';
                      } else if(menu.jumpType == 'noviceTask') {
                        return '/task/novice';
                      } else if(menu.jumpType == 'video') {
                        return '/index/video';
                      } else if(menu.jumpType == 'tradeGoods') {
                        return '/trade/good?source=czjl';
                      } else if(menu.jumpType == 'webview') {
                        let url = URI(menu.url)
                          .addQuery('username', localStorage.getItem('username'))
                          .addQuery('token', localStorage.getItem('token'))
                          .addQuery('location_url', window.location.origin)
                          .addQuery('mb', localStorage.getItem('mb'))
                          .addQuery('origin', 'react')
                          .toString();
                        return url;
                      } else if(menu.jumpType == 'authoriry_record') {
                        return '/user/authority';
                      } else if(menu.type == 'vouchandgift') {
                        return '/giftproject'
                      } else if(menu.type == 'signgift') {
                        return '/signgift'
                      }
                    })()} user={menu.isCheckLogin == 1?true:false} onClick={()=>clickIconOfHomePage(menu)}>
                    <div className="icon"><Image src={menu.icon} alt=""/></div>
                    <div className="name">{menu.title}</div>
                  </NavLink>
                  } 
                })()}
                </div>)}
              <div className="scrollbar">
                <div className="thumb" ref={scrollbarRef}/>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    {/** 横幅广告 **/}
    {popularGame.length > 0 ?
    <div className="index-popularGame">
      <Swiper onSwiper={swiper => swipers.push(swiper)} 
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }} 
        loop={popularGame.length > 1 ? true : false} 
        loopedSlides={1} >
        {popularGame.map((popular,index) => <SwiperSlide key={index}>
          {popular.type === 'inner_web' || popular.type === 'onner_web' || popular.type === 'rechargePtb' ?
          <NavLink to={(() => {
            let url = URI(popular.value)
                .addQuery('username', localStorage.getItem('username'))
                .addQuery('token', localStorage.getItem('token'))
                .addQuery('location_url', window.location.origin)
                .addQuery('mb', localStorage.getItem('mb'))
                .addQuery('origin', 'react')
                .toString();
              return url;
            })()}
            iframe={{title: popular.title}}
            className="popular"
            user={popular.is_check_login == true?true:false}
            onClick={()=>pageActivityBanner(popular)}
          >
            {popular.icon?.length > 0 && <img className="small-pic" src={popular.icon} alt="" />}
            <img style={{width:'100%'}} src={popular.img?popular.img:'/resources/placeholder/index-active.png'} alt=""/>
          </NavLink>
          :  popular.type === 'work_weixin' || popular.type === 'outer_web' ? 
          <div className="popular" onClick={()=>{window.open(popular.value, "_blank");}}>
            {popular.icon?.length > 0 && <img className="small-pic" src={popular.icon} alt="" />}
            <img style={{width:'100%'}} src={popular.img?popular.img:'/resources/placeholder/index-active.png'} alt=""/>
          </div>
          : <Link to={(() => {
            if(popular.type === 'special' || popular.type === 'project' || popular.type === 'gameProject') {
              return '/game/project?id=' + popular.value.id;
            }
            if(popular.type === 'news_info') {
              return '/message/detail?id=' + popular.value;
            }
            if(popular.type === 'game_info' || popular.type === 'game') {
              return '/game?id=' + popular.value;
            }
            if(popular.type === 'invite') {
              return config?.inviteFriendsType == 2 ? "/newinvite" : "/invite";
            }
            if(popular.type === 'rechargeVip') {
              return "/save/newvip";
            }
            if(popular.type === 'moneySavingCard') {
              return "/save/newcard";
            }
            if(popular.type == 'vouchandgift') {
              return '/giftproject'
            }
            if(popular.type == 'signgift') {
              return '/signgift'
            }
          })()}><img src={popular.img?popular.img:'/resources/placeholder/index-active.png'} alt=""/></Link>
          }
        </SwiperSlide>)}
      </Swiper>
    </div>
    : null}

    {/** 重磅推荐 **/}
    {
      popularGame.length == 0 && Object.keys(recommendTop).length > 0 ? <div className="index-recommend-top" style={{backgroundImage:'url(/resources/index/recomment_top_banner.png)'}}>
        <Link to={(()=>{
          if(recommendTop.type == 'game_info') {
            return '/game?id=' + recommendTop.value;
          } else if(recommendTop.type == 'news_info') {
            return '/message/detail?id=' + recommendTop.value;
          }
        })()} className="item">
          <div className="tips">
            重磅推荐
          </div>
          <div className="icon"><Image src={recommendTop.img}/></div>
          <div className="text">
            <div className="name">
              {recommendTop.title}
            </div>
            <div className="info1">
              {recommendTop.info}
            </div>
          </div>
          <div className="operate">
            立即下载
          </div>
        </Link>
      </div>
      : null
    }

    {datas.map((item,index) => item.hide ? null : <div key={index} className={'index-data ' + item.tag}>
      {item.tag !== 'specialBanner' && item.tag !== 'hotSearchWord' && item.tag !== 'guessYouLike' && item.tag !== 'videoList' && item.outstyle != 3 && item.tag !== 'agame'
        ? <div className={'data-head ' + item.tag}>
          <div className="text"><img src={item.title_image} /></div>
          {item.tag === 'special' ? <Link 
          onClick={()=>Manager.push({event_id:item.event_id})}
            to={(() => {
              if(item.style == 6) {
                return '/party/beta?id=' + item.id
              } else {
                if (item.jump_type === 'extraLeak') {
                  return `/index/trade?title=${item.title}`;
                } else {
                  return '/game/project?id=' + item.id;
                }
              }
            })()} 
            className="more">更多<img src="/resources/welfare/left-back.png" className="left-back"/></Link> : null}
          {item.tag === 'startingGames' ? <Link onClick={()=>Manager.push({event_id:item.event_id})} to="/game/today-start" className="more">更多<img src="/resources/welfare/left-back.png" className="left-back"/></Link> : null}
          {item.tag === 'reserverGames' ? <Link onClick={()=>Manager.push({event_id:item.event_id})} to="/game/reserve" className="more">更多<img src="/resources/welfare/left-back.png" className="left-back"/></Link> : null}
          {item.tag === 'diycategory' ? <Link onClick={()=>Manager.push({event_id:item.event_id})} to={'/game/project?id=' + item.id} className="more">更多<img src="/resources/welfare/left-back.png" className="left-back"/></Link> : null}
        </div>
        : null}
        {item.outstyle != 3 && item.tag !== 'reserverGames' && item.tag !== 'agame' && item.tag !== 'guessYouLike'
        ?
        <div className="tips">
            {item.tips}
        </div>
        :null}

      <div className={'data-body ' + item.tag}>
        {(() => {
          if (item.tag === 'recommendGames') {
            return <div className="items">
              {item.list?.map((game,index) => <Link key={index} to={'/game?id=' + game.game_id + '&agent=' + agent} className="item" onClick={()=>clickTheGameInTheTopic(item)}>
                <div className="icon"><Image src={game.game_image.thumb}/></div>
                <div className="name">{game.game_name}</div>
                <div className="info">{game.game_classify_type.trim().replace(' ',' · ')}</div>
              </Link>)}
            </div>
          } else if (item.tag === 'special') {
            if(item.outstyle == '2') {
              return <Link 
                to={(()=>{
                  //跳转类型
                  if(item.jump_type == 'tradeGoods') {
                    return '/trade/good?source=czjl';
                  } else if(item.jump_type == 'extraLeak') {
                    return `/index/trade?title=${item.title}`;
                  } else {
                    return '/game/project?id=' + item.id;
                  }
                })} onClick={()=>clickTheTopicToSeeMore(item)} >
                <Image src={item.top_image} alt="" className="project-img-four"/>
              </Link>
            } else if(item.outstyle == '3') {
              return <div className="">
                {[item.game_info]?.map((game,index) => <div key={index} className={'project-item-third'}><Link 
                  to={(()=>{
                    //跳转类型
                    if(item.jump_type == 'tradeGoods') {
                      return '/trade/good?source=czjl';
                    } else if(item.jump_type == 'extraLeak') {
                      return `/index/trade?title=${item.title}`;
                    } else {
                      return '/game?id=' + game.game_id + '&agent=' + agent;
                    }
                  })} onClick={()=>clickTheGameInTheTopic(game.game_name, item?.title ? item.title : '专题')}>
                  {/*to={'/game?id=' + game.game_id} >*/}
                    <div className="item-base">
                      <div className="icon"><Image src={game.game_image.thumb}/></div>
                      <div className="text">
                        <div className="name">{game.game_name}</div>
                        <div className="info1">
                          {
                            game.nameRemark ?
                            <span className="nameRemark">
                              {game?.nameRemark}&nbsp;
                            </span>
                            : null
                          }
                          {/* {game.game_classify_type} */}
                          {/* {game.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")} */}
                          {/* {game.game_species_type != 3 ? <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</> : null} */}
                          {
                            game.nameRemark ?
                            <>{game.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")}</>
                            :
                            <>{game.game_classify_type}</>
                          }
                          {!game.nameRemark && game.game_species_type != 3 ? <>&nbsp;·&nbsp;{game.howManyPlay}人在玩</> : null}
                        </div>
                        <div className="info2">
                          {game.game_desc ? game.game_desc.split('+').map((item,index) => <div key={index}
                            className="tag">{item}</div>) : <span style={{color: infoTextColor}}>{game.introduction}</span>}
                        </div>
                      </div>
                    </div>
                    <div className="item-img"><Image src={item.top_image} alt="" className="project-img-four"/></div>
                    <div className="item-desc">{item.tips}</div>
                  </Link>
                </div>)}
              </div>
            } else if(item.style == '6') {
              return <Link 
              to={"/party/beta?id=" + item.id} onClick={()=>clickTheTopicToSeeMore(item)} >
                <Image src={item.top_image} alt="" className="project-img-four"/>
              </Link>
            } else {
              return <Link 
              to={(()=>{
                //跳转类型
                if(item.jump_type == 'tradeGoods') {
                  return '/trade/good?source=czjl';
                } else if(item.jump_type == 'extraLeak') {
                  return `/index/trade?title=${item.title}`;
                } else {
                  return '/game/project?id=' + item.id;
                }
              })} onClick={()=>clickTheTopicToSeeMore(item)} >
              {/*to={'/game/project?id=' + item.id}>*/}
                <Image src={item.top_image} alt="" className="project-img-four"/>
              </Link>
            }
          } else if (item.tag === 'hotGames') {

            return <div className="">
              {(() => {
                return item.list?.map((game, index) => game?<Link key={index} to={'/game?id=' + game.game_id + '&agent=' + agent} className="item" onClick={()=>clickTheGameInTheTopic(game.game_name, item?.title ? item.title : '专题')}>
                    <div className="entity">
                      <div className="icon">
                        <Image src={game.game_image.thumb}/>
                        {/* <div className="scrole-stat">
                          <img src="/resources/game/stat.png" />
                          <span>{game.score}</span>
                        </div> */}
                        {
                          game?.lablePosition == 2 && game.top_lable != '' ?
                          <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
                            {game.top_lable}
                          </div>
                          : null
                        }
                      </div>
                      <div className="text">
                        <div className="name" style={{width: game.discount < 1 ? '82%' : '100%' }}>
                          {/* {game.top_lable != '' ? <div className="span">{game.top_lable }</div> : null }{game.game_name} */}
                          <div className={" " + (game.nameRemark ? " name-new" : "")}>
                            {game?.lablePosition == 1 && game.top_lable != '' ? <div className="span">{game.top_lable }</div> : null }
                            {game.game_name}
                          </div>
                        </div>
                        <div className="info1" style={{marginTop:Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play ? '5px' : '0'}}>
                          <img src="/resources/game/stat.png" />
                          <span>{game.score}</span>
                          {game.game_classify_type}
                          <Subtitle>{game.nameRemark}</Subtitle>
                        </div>
                        {/* <div className="info2">
                          {game.game_desc ? game.game_desc.split('+').map((item,index) => <span key={index} className="tag">{item}</span>) : <span style={{color: infoTextColor}}>{game.introduction}</span>}
                        </div> */}
                        <div className="info3">
                        {
                          game.bottom_lable ? <>
                          {Object.keys(game.bottom_lable).length > 0 ? 
                          <div >
                            <span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                          </div> : null } </> : null
                        }
                        {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
                        {game.is_support_accele ? <div className="speed">可加速</div> : null}
                        {game.is_easy_play ? <div className="speed">省心玩</div> : null}
                      </div>
                        {game.discount < 1 ? <div className="discount" style={{backgroundImage:'url(/resources/game/discount-text-bg.png)'}}><span>{game.discount * 10}</span>折</div> : null}
                      </div>
                    </div>
                    {index == 0 ? 
                      <div className="entity-img">
                        <img src={game.banner_url} />
                      </div>
                    :null}
                  </Link>:null);
              })()}
            </div>
          } else if (item.tag === 'imagejump') {
            return <div className="items">
              {item.list?.map((item,index) => <NavLink key={index} to={'/' + item.jump_type} user={parseInt(item.is_check_login)}
                                                       className="item" onClick={()=>clickTheTopicToSeeMore(item)}>
                <Image src={item.top_image} alt=""/>
              </NavLink>)}
            </div>
          } else if (item.tag === 'startingGames') {
            return <div className="items">
              {item.list?.map((game,index) => <Link key={index} to={'/game?id=' + game.game_id + '&agent=' + agent} className="item" onClick={()=>clickTheGameInTheTopic(game.game_name, item?.title ? item.title : '专题')}>
                <div className="container">
                  <div className="img"><Image src={game.game_ur_list[0]}/></div>
                  <div className="time">{moment(game.starting_time * 1000).format('HH:mm')}</div>
                  <div className="info">
                    <div className="name">{game.game_name}</div>
                    <div className="desc">{game.game_classify_type}</div>
                  </div>
                </div>
              </Link>)}
            </div>
          } else if (item.tag === 'specialBanner') {
            return <div className="items clearfix">
              {item.list?.map((item,index) => <NavLink
                className="item"
                key={index}
                to={(() => {
                  if (item.type === 'h5') {
                    return item.param;
                  }
                })()}
                iframe={item.type === 'h5' ? {} : false}
                user={item.type === 'h5' && item.is_verification_login}
                onClick={()=>clickTheTopicToSeeMore(item)}
              >
                <Image src={item.banner_img} alt=""/>
              </NavLink>)}
            </div>
          } else if (item.tag === 'reserverGames') {
            return <Swiper onSwiper={swiper => swipers.push(swiper)}>
              {item.list?.map((game,index) => <SwiperSlide key={index}>
                <Link to={'/game?id=' + game.game_id + '&agent=' + agent} className="item" onClick={()=>clickTheGameInTheTopic(game.game_name, item?.title ? item.title : '专题')}>
                  <div className="img" style={{backgroundImage: 'url(' + game.video_img_url + ')'}}/>
                  <div className="container">
                    <div className="icon"><Image src={game.game_image.thumb}/></div>
                    <div className="text">
                      <div className="name">{game.game_name}</div>
                      <div className="time">{moment(game.starting_time * 1000).format('MM月DD日 HH:mm')}首发</div>
                    </div>
                    <div className="btn">查看</div>
                  </div>
                </Link>
              </SwiperSlide>)}
            </Swiper>
          } else if (item.tag === 'cate') {
            item.hide = item.list.length === 0;
            // return <div className="items">
            //   {item.catelist.map((game, index) => index < 6 ?
            //     <Link to={'/game?id=' + game.game_id} className="item">
            //       <div className="icon">
            //         <Image src={game.game_image.thumb}/>
            //         <div className="badge">
            //           {game.game_desc.split('+')[1]}
            //         </div>
            //       </div>
            //       <div className="name">{game.game_name}</div>
            //     </Link> : null)}
            // </div>


            return <div className="items">{item.catelist.map((game, index) => index < 3 ? <Link key={index} to={'/game?id=' + game.game_id + '&agent='+agent} className="item" onClick={()=>clickTheGameInTheTopic(game.game_name, item?.title ? item.title : '专题')}>
            <div className="entity">
              <div className="icon">
                <Image src={game.game_image.thumb}/>
                {/* <div className="scrole-stat">
                  <img src="/resources/game/stat.png" />
                  <span>{game.score}</span>
                </div> */}
                {
                  game?.lablePosition == 2 && game.top_lable != '' ?
                  <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
                    {game.top_lable}
                  </div>
                  : null
                }
              </div>
              <div className="text">
                <div className="name">
                  {/* {game.top_lable != '' ? <div className="span">{game.top_lable }</div> : null }{game.game_name} */}
                  <div className={" " + (game.nameRemark ? " name-new" : "")}>
                    {game?.lablePosition == 1 && game.top_lable != '' ? <div className="span">{game.top_lable }</div> : null }
                    {game.game_name}
                  </div>
                </div>
                <div className="info1" style={{marginTop:Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play ? '5px' : '0'}}>
                  <img src="/resources/game/stat.png" />
                  <span>{game.score}</span>
                  {game.game_classify_type}
                  <Subtitle>{game.nameRemark}</Subtitle>
                </div>
                {/* <div className="info2">
                  {game.game_desc ? game.game_desc.split('+').map((item,index) => <span key={index} className="tag">{item}</span>) : <span style={{color: infoTextColor}}>{game.introduction}</span>}
                </div> */}
                <div className="info3">
                  {
                    game.bottom_lable ? <>
                    {Object.keys(game.bottom_lable).length > 0 ? 
                    <div >
                      <span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                    </div> : null } </> : null
                  }
                  {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
                  {game.is_support_accele ? <div className="speed">可加速</div> : null}
                  {game.is_easy_play ? <div className="speed">省心玩</div> : null}
                </div>
                {game.discount < 1 ? <div className="discount" style={{backgroundImage:'url(/resources/game/discount-text-bg.png)'}}><span>{game.discount * 10}</span>折</div> : null}
              </div>
            </div>
            <div className="entity-img">
              <img src={game.banner_url} />
            </div>
            </Link>:null)}</div>;
          } else if (item.tag === 'agame') {
            return <Link className="item" to={"/game?id=" + item?.list[0]?.game_id}>
              <div className="entity-img">
                <img src={item?.list[0]?.banner_url} />
                <div className="img-tips" style={{backgroundImage: 'linear-gradient(#f0ebeb1f, '+item.bannerBgColor+', '+item.bannerBgColor+')'}}>
                  {/* 真开局送绝版龙魂七彩祥瑞！ */}
                  {item?.tips}
                </div>
              </div>
              <div className="entity-dividing" style={{backgroundColor: ' ' + item.bannerBgColor +' '}}>
                <div className="dividing-line">
                  {/* {item?.agamelist[0]?.banner_url} */}
                  <img src="/resources/index/dividing-line.png" />
                </div>
                <div className="entity">
                  <div className="icon"><Image src={item?.list[0]?.game_image.thumb}/></div>
                  <div className="text">
                    <div className="name">
                      <div className={" " + (item?.list[0]?.nameRemark ? " name-new" : "")}>
                        {item?.list[0]?.game_name}
                      </div>
                      {item?.list[0]?.nameRemark ? <div className="nameRemark-new" style={{clear: 'both'}}>{item?.list[0]?.nameRemark}</div> : <div ></div>}
                    </div>
                    <div className="info1">
                      {item?.list[0]?.game_classify_type}
                      {item?.list[0]?.game_species_type != 3 ? <>&nbsp;·&nbsp;{item?.list[0]?.howManyPlay}人在玩</> : null}
                    </div>
                    <div className="info2">
                      {item?.list[0]?.game_desc ? item.list[0].game_desc.split('+').map((item,index) => <div key={index} className="tag">{item}</div>) : <span style={{color:infoTextColor}}>{item?.list[0]?.introduction}</span>}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          } else if (item.tag === 'videoList') {
            return <div className="videoList">

            </div>
          } else if (item.tag === 'hotCate') {
            return <div className="items clearfix">
              {item.list?.map((item, index) => index < 6 ? <div key={index} className="item">
                <div className="entity">
                <div className="container" onClick={() => {
                  Manager.push({event_id: item.event_id})
                  redux.dispatch({
                    type: 'type',
                    data: {
                      screenRouter: {
                        screen: '/game',
                        type: 0,
                        tag: item.game_classify_id,
                      },
                    }
                  });
                }}>{item.game_classify_name}</div></div>
              </div> : null)}
            </div>
          } else if (item.tag === 'list') {
            return <div className="">
              {(() => {
                return item.list?.map((game, index) => game?<Link key={index} to={'/game?id=' + game.game_id + '&agent=' + agent} className="item" onClick={()=>clickTheGameInTheTopic(game.game_name, item?.title ? item.title : '专题')}>
                    <div className="entity">
                      <div className="icon">
                        <Image src={game.game_image.thumb}/>
                        {/* <div className="scrole-stat">
                          <img src="/resources/game/stat.png" />
                          <span>{game.score}</span>
                        </div> */}
                      </div>
                      <div className="text">
                        <div className="name" style={{width: game.discount < 1 ? '82%' : '100%' }}>
                          <div className={" " + (game.nameRemark ? " name-new" : "")}>
                            {game.top_lable != '' ? <div className="span">{game.top_lable }</div> : null }
                            {game.game_name}
                          </div>
                          {game.nameRemark != '' ? <div className="nameRemark">{game.nameRemark}</div> : null}
                        </div>
                        <div className="info1" style={{marginTop:(Object.keys(game.bottom_lable).length > 0 ? '0' : '8px')}}>
                          <img src="/resources/game/stat.png" />
                          <span>{game.score}</span>
                          {game.game_classify_type}
                          {game.game_species_type != 3 ? <>&nbsp;·&nbsp;{game.howManyPlay}人在玩</> : null}
                        </div>
                        {/* <div className="info2">
                          {game.game_desc ? game.game_desc.split('+').map((item,index) => <span key={index} className="tag"><span className="tagtips"><span className="first-test">{item.slice(0,1)}</span>{item.slice(1)}</span> </span>) : <span style={{color: infoTextColor}}>{game.introduction}</span>}
                        </div> */}
                        {
                          game.bottom_lable ? 
                          <>
                          {Object.keys(game.bottom_lable).length > 0 ? 
                          <div className="info3">
                            <span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                          </div> : null }
                          </>
                          : null
                        }
                        {game.discount < 1 ? <div className="discount" style={{backgroundImage:'url(/resources/game/discount-text-bg.png)'}}><span>{game.discount * 10}</span>折</div> : null}
                      </div>
                    </div>
                  </Link>:null);
              })()}
            </div>
          } else if (item.tag === 'guessYouLike') {
            return <div>
              <WantPlay item={item}></WantPlay>
            </div>
          } else if (item.tag === 'hotSearchWord') {
            return <div>
              <AllSearch item={item}></AllSearch>
            </div>
          }
        })()}
      </div>
    </div>)}
    <div className="index-tips">
      <div className="text">更多游戏请到游戏库中查看</div>
    </div>
    {/* 备案号 */}
    {
      copyright ?
      <div className="index-provider" style={{textAlign:'center'}}>
        <a href="https://beian.miit.gov.cn/">{copyright}</a>
      </div>
      :null
    }
    {/**下载横幅 **/}
    {/* {
      (!mobileDetect.is('iPhone') && !mobileDetect.is('iPad')) && downBoxState ?
      <div className="index-g-ad">
          <div className="close" onClick={() => setDownBoxState(false)} style={{backgroundImage: 'url(http://cdn1.oss.wakaifu.com/active/20210401/close.png)'}}></div>
          <div className="left">
              <div className="icon">
                  <img src={app.oapp_icon} />
              </div>
              <div className="txt">
                  <div className="name">{app.appname}</div>
                  <p>海量变态福利手游大全</p>
              </div>
          </div>
          <div className="right">
              <a href={"http://app.milu.com/?a=" + agent}  className="btn">立即下载</a>
          </div>
      </div>
      : null
    } */}
    
    {/* 送首充and代金券 */}
    <div className="index-fixdiv">
      {
        // user.novice_fuli_v2101_show == 1 || user.is_show_reg_between_8day_30day ?
        // <>
        // <NavLink 
        // to={(() => {
        //     let url = URI(user.novice_fuli_v2101_url)
        //       .addQuery('username', localStorage.getItem('username'))
        //       .addQuery('token', localStorage.getItem('token'))
        //       .addQuery('location_url', window.location.origin)
        //       .addQuery('origin', 'react')
        //       .toString();
        //     return url;
        //   })()}
        //   iframe={{title: '新人福利'}}
        // >
        //   {user.novice_fuli_v2101_show ? <img src="https://cdn1.oss.wakaifu.com/active/new-agent-website/index/home_novice_benefits.gif" className="djj"/> : <img src="https://cdn1.oss.wakaifu.com/active/new-agent-website/index/home_novice_benefits1.gif" className="djj"/>}
        // </NavLink></>
        // : 
        // (user.is_show_reg_gt_30day ? <><NavLink to={(() => {
        //   let url = URI(user.reg_gt_30day_url)
        //         .addQuery('username', localStorage.getItem('username'))
        //         .addQuery('token', localStorage.getItem('token'))
        //         .addQuery('location_url', window.location.origin)
        //         .addQuery('origin', 'react')
        //         .toString();
        //     return url;
        //   })()}
        //   iframe={{title: '老用户回归活动'}}
        // ><img src="https://cdn1.oss.wakaifu.com/active/new-agent-website/index/home_novice_benefits2.gif" className="djj"/></NavLink> </>: null)
        // (user.is_show_reg_gt_30day ? <><NavLink to="/party/regression"
        //   iframe={{title: '老用户回归活动'}}
        // ><img src="https://cdn1.oss.wakaifu.com/active/new-agent-website/index/home_novice_benefits2.gif" className="djj"/></NavLink> </>: null)
      }      

      {
        isAccord ? (
        indexActiveState || config.is_activity == '1' ?
        <NavLink onClick={()=>{
            Manager.push({event_id:'B-9'})
          }} user={activeInfo.needlogin} to={(() => {
            if(activeInfo.url) {
              if (!localStorage.getItem('username')){
                let url = URI(activeInfo.url)
                  .addQuery('location_url', window.location.origin)
                  .addQuery('origin', 'react')
                  .toString();
                return url;
              }else{
                let url = URI(activeInfo.url)
                  .addQuery('username', localStorage.getItem('username'))
                  .addQuery('token', localStorage.getItem('token'))
                  .addQuery('location_url', window.location.origin)
                  .addQuery('origin', 'react')
                  .toString();
                return url;
              }
            }
          })()} iframe={{title: activeInfo.name}}>
          <img src={config.activity_thumb}  className="ssc"/></NavLink>
        : null) : null
      }

      {/* {
        isAccord ? (
        indexActiveState || testConfig.is_activity == '1' ?
          <NavLink to={(() => {
            if(activeInfo.url) {
              let url = URI(activeInfo.url)
                .addQuery('username', localStorage.getItem('username'))
                .addQuery('token', localStorage.getItem('token'))
                .addQuery('location_url', window.location.origin)
                .addQuery('origin', 'react')
                .toString();
              return url;
            }
          })()} iframe={{title: activeInfo.name}}>
          <img src={testConfig.activity_thumb}  className="ssc"/></NavLink>
        : (user.is_finish_newtask ?
          null
          : (!indexActiveState ? <img src="https://cdn1.oss.wakaifu.com/active/new-agent-website/index/ssc.png?v=20210818.2" className="ssc" onClick={()=>setFirseChargeState(true)} /> : null) )) : null
      } */}
    </div>

    {/**首充活动弹框 */}
    {
      isAccord ?
      // (indexActiveState || packageGiftState || firstChargeState || noLoginPop ?
      (indexActiveState || packageGiftState ?
      <>
      <div className="index-first-charge" id="first-charge"  ref={ref}>
      {/* onTouchMove={touchmove} */}
        {
          (indexActiveState ? 
            <>
            <NavLink user={activeInfo.needlogin}
            to={(() => {
              if(activeInfo.url) {
                if (!localStorage.getItem('username')){
                  let url = URI(activeInfo.url)
                    .addQuery('location_url', window.location.origin)
                    .addQuery('origin', 'react')
                    .toString();
                  return url;
                }else{
                  let url = URI(activeInfo.url)
                    .addQuery('username', localStorage.getItem('username'))
                    .addQuery('token', localStorage.getItem('token'))
                    .addQuery('location_url', window.location.origin)
                    .addQuery('origin', 'react')
                    .toString();
                  return url;
                }
              }
            })()}
            iframe={{title: activeInfo.name}}
            onClick={closePop}
            >
              <img src={activeInfo.img} onClick={closePop}/>
            </NavLink>
            </>
          :
          (!indexActiveState && packageGiftState ? 
            user.novice_fuli_v2101_show == 1 || user.is_show_reg_between_8day_30day? 
              <>
              <NavLink to={(() => {
                let url = URI(user.novice_fuli_v2101_url)
                      .addQuery('username', localStorage.getItem('username'))
                      .addQuery('token', localStorage.getItem('token'))
                      .addQuery('location_url', window.location.origin)
                      .addQuery('mb', localStorage.getItem('mb'))
                      .addQuery('origin', 'react')
                      .toString();
                  return url;
                })()} iframe={{title: '新人福利'}} onClick={closePop}>
                {user.novice_fuli_v2101_show ? <img src="https://cdn1.oss.wakaifu.com/active/new-agent-website/index/t3.png"  onClick={closePop}/> : <img src="https://cdn1.oss.wakaifu.com/active/new-agent-website/index/t4.png"  onClick={closePop}/>}
              </NavLink>
              </>
            :
            (user.is_show_reg_gt_30day ? 
              <>
              <NavLink to="/party/regression" iframe={{title: '老用户回归活动'}} onClick={closePop}>
                <img src="https://cdn1.oss.wakaifu.com/active/new-agent-website/index/t1.png"  onClick={closePop}/>
              </NavLink>
              </>
            : null)
          :
          // (!indexActiveState && !packageGiftState && firstChargeState ?
          //   <>
          //   <NavLink to={'/screen-welfare'} onClick={closePop}>
          //     <img src="https://cdn1.oss.wakaifu.com/active/new-agent-website/songshouc.png"  onClick={closePop}/>
          //   </NavLink>
          //   </>
          // : (!indexActiveState && noLoginPop ?
          //   <>
          //   <NavLink onClick={() => setNoLoginPop(false)}>
          //     <img src="https://cdn1.oss.wakaifu.com/active/new-agent-website/songshouc.png" onClick={closePop}/>
          //   </NavLink>
          //   </>
          // : null) ) 
            null
          ) )
        }
        <div className="close001">
          <img src="https://cdn1.oss.wakaifu.com/active/20210401/close.png" onClick={closePop}/>
        </div>
      </div>
      </>
      : null) : null
    }

    {
    browserState ?
    <CopyToClipboard text={browserUrlText}>
        <div style={{display:(browserCopys ? 'none' : '')}} className="index-wechat-img-bg" onClick={()=>{setBrowserCopys(true)}}></div>
    </CopyToClipboard>
    : null}


    {browserCopys ?
      <CopyMask></CopyMask>
    : null}

    {/* {tradesStatePop ?
    <div className="index-tradesreject">
      <div className="container">
        <div className="body">
          <div className="icon"><img src="/resources/rebate/confirm.png" alt=""/></div>
          <div className="title">{tradesReject.content.title}</div>
        </div>
        <div className="info">
          {tradesReject.content.desc}
        </div>
        <div>
          <NavLink className="btns" to={(() => {
            if(tradesReject.link_info.link_route == 'trades_info') {
              return "/trade/detail?id=" + tradesReject.link_info.link_value;
            }
          })()} onClick={()=>setTradesStatePop(false)}>
            {tradesReject.content.button}
          </NavLink>
        </div>
      </div>
      <div className="close">
        <img src="https://cdn1.oss.wakaifu.com/active/20210401/close.png" onClick={()=>setTradesStatePop(false)}/>
      </div>
    </div>
    :null} */}

    {spweardState || gamePopState ? 
    <div className="index-spweard">
      <div className="spweard-site">
        <div className="spweard-main">
          <div className="spweard-imt-t">
            <img src="/resources/index/spweard-img-title.png"/>
          </div>
          {/* <div className="close" onClick={() => setExchangeState(false)}>
            <img src="/resources/user/close.png" />
          </div> */}
          <div className="spweard-title">
          温馨提示
          </div>
          <div className="info">
            <p>打开您刚刚点击的{gamePopState ? '游戏' : ''}</p>
            {gamePopState ? '' :
              <p className="des2">{params.zt}类游戏</p>
            }
          </div>
          <div className="spweard-btn">
            <div className="item btn-about" onClick={()=>{setSpweardState(false);setGamePopState(false)}}>
              否
            </div>

            {spweardState ? 
            <NavLink
              to={(() => {
                let url = URI(params.zu)
                    .addQuery('username', localStorage.getItem('username'))
                    .addQuery('token', localStorage.getItem('token'))
                    .addQuery('location_url', window.location.origin)
                    .addQuery('mb', localStorage.getItem('mb'))
                    .addQuery('origin', 'react')
                    .toString();
                return url;
              })()} iframe={{title: params.zt}} user={false} onClick={()=>setSpweardState(false)} className="item btn-confirm">
              是
            </NavLink>
            :
            <NavLink to={"/game?id=" + params.b} user={false} onClick={()=>setGamePopState(false)} className="item btn-confirm btn-game-pop">
              是
            </NavLink>
            }
          </div>
        </div>
      </div>
    </div>
    : null}


    {
      alertList?.length > 0 ?
      alertList.map((item,index) => item.display ? <div key={index}>
        {(()=>{
          if(item.jumpType == 'outer_web') {
            return <div className="index-first-charge index-game-info-img" id="first-charge" >
              <div onClick={()=>{closeNewTradePop(item);newoper(item)}}>
                <img src={item.img} onLoad={()=>setAlertPicCompletion(true)}/>
              </div>
              {alertPicCompletion && <div className="close001" onClick={()=>{closeNewTradePop(item)}}>
                <img src="https://cdn1.oss.wakaifu.com/active/20210401/close.png" />
              </div>}
            </div>
          } else if(item.jumpType == 'agentMessage') {
            return <div className="index-first-messageAlert ">
              <div className="exit-site">
                <div className="exit-main">
                  <div className="exit-top-img">
                    <img src="/resources/index/message-top-img.png" />
                  </div>
                  <div className="bar-top" onClick={()=>{closeNewTradePop(item)}}>
                    <img src="/resources/party/couple-close.png" />
                  </div>
                  <div className="exit-title">
                  {item.title}
                  </div>
                  <div className="info">
                    <p>{item.content}</p>
                  </div>
                  <div className="exit-btn">
                    <NavLink to={"/message/infobox?id=" + item.value} className="item btn-about" onClick={()=>{closeNewTradePop(item)}}>
                      查看详情
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          } else if(item.jumpType == 'inner_web') {
            return <div className="index-first-charge index-game-info-img" id="first-charge" >
              <div onClick={()=>{closeNewTradePop(item);newoper(item)}}>
                <img src={item.img} onLoad={()=>setAlertPicCompletion(true)}/>
              </div>
              {alertPicCompletion && <div className="close001" onClick={()=>{closeNewTradePop(item)}}>
                <img src="https://cdn1.oss.wakaifu.com/active/20210401/close.png" />
              </div>}
            </div>
          } else if(item.jumpType == 'rebate_info') {
            return <div className="index-first-messageAlert ">
              <div className="exit-site">
                <div className="exit-main">
                  <div className="exit-top-img">
                    <img src="/resources/index/message-top-img.png" />
                  </div>
                  <div className="bar-top" onClick={()=>{closeNewTradePop(item)}}>
                    <img src="/resources/party/couple-close.png" />
                  </div>
                  <div className="exit-title">
                  {item.title}
                  </div>
                  <div className="info">
                    <p>{item.content}</p>
                  </div>
                  <div className="exit-btn">
                    <NavLink to={"/rebate/detail?id=" + item.value} className="item btn-about" onClick={()=>{closeNewTradePop(item)}}>
                      查看详情
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          }
        })()}
      </div> : null)
      :null
    }

  {/** bottom-banner **/}
  {/* {
    Object.keys(bottomGame).length > 0 ? bottomBannerState ? <div className="index-bottom-banner">
      <div className="close" style={{backgroundImage: 'url(/resources/index/white-close.png)'}} onClick={()=>{setBottomBannerState(false)}}></div>
      <div className="img-body" onClick={()=>{bottomOper(bottomGame)}}>
        <img src={bottomGame.img} />
      </div>
    </div>
    : null
    : null
  } */}

  {
    Object.keys(bottomGame).length > 0 ? bottomBannerState ? <>
      {(()=>{
        if(bottomGame.type == 'inner_web') {
          return <div className="index-bottom-banner">
            {/* <div className="close" style={{backgroundImage: 'url(/resources/index/white-close.png)'}} onClick={()=>{setBottomBannerState(false)}}></div> */}
            <div className="close" onClick={()=>{setBottomBannerState(false)}}>
              <img src="/resources/index/white-close.png" />
            </div>
            <NavLink to={(() => {
            let url = URI(bottomGame.value)
                .addQuery('username', localStorage.getItem('username'))
                .addQuery('token', localStorage.getItem('token'))
                .addQuery('location_url', window.location.origin)
                .addQuery('mb', localStorage.getItem('mb'))
                .addQuery('origin', 'react')
                .toString();
              return url;
            })()}
            iframe={{title: bottomGame.title}}
            className="img-body" 
            user={bottomGame.is_check_login == true?true:false}
          >
            <img src={bottomGame.img} />
          </NavLink>
          </div>
        } else {
          return <div className="index-bottom-banner">
            {/* <div className="close" style={{backgroundImage: 'url(/resources/index/white-close.png)'}} onClick={()=>{setBottomBannerState(false)}}></div> */}
            <div className="close" onClick={()=>{setBottomBannerState(false)}}>
              <img src="/resources/index/white-close.png" />
            </div>
            <div className="img-body" onClick={()=>{bottomOper(bottomGame)}}>
              <img src={bottomGame.img} />
            </div>
          </div>
        }
      })()}
      {/* <div className="index-bottom-banner">
        <div className="close" style={{backgroundImage: 'url(/resources/index/white-close.png)'}} onClick={()=>{setBottomBannerState(false)}}></div>
        <div className="img-body" onClick={()=>{bottomOper(bottomGame)}}>
          <img src={bottomGame.img} />
        </div>
      </div> */}
    </>
    : null
    : null
  }

  {tradeGamePop ? 
    <div className="index-first-charge index-game-info-img" id="first-charge" >
      {/* <Link to={"/game?id=" + tradeNotice.link_info.link_value} >
        <img src={tradeNotice.content.image} />
      </Link> */}
      <div onClick={()=>{newoper()}}>
        <img src={tradeNotice.content.image} />
      </div>
      <div className="close001">
        <img src="https://cdn1.oss.wakaifu.com/active/20210401/close.png" onClick={closeTradePop}/>
      </div>
    </div>
    : null}

  </div>;
}